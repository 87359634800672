import PropTypes from "prop-types";
import React from "react";
import Modal from "react-modal";
import ModalCloseButton from "./ModalCloseButton";

const styles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.541176)"
  }
};

const TermsModal = ({ contentLabel, open, title, content, onRequestClose }) => (
  <Modal
    contentLabel={contentLabel}
    className="modal-dialog"
    isOpen={open}
    style={styles}
  >
    <div className="modal-content">
      <ModalCloseButton onClick={onRequestClose} />
      <h1>{title}</h1>
      {content ? (
        <div className="text-content">
          <textarea value={content} readOnly />
        </div>
      ) : (
        <div className="loading" style={{ height: 350 }}>
          <div className="spinner" />
        </div>
      )}
    </div>
  </Modal>
);

TermsModal.propTypes = {
  contentLabel: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.node.isRequired,
  content: PropTypes.string.isRequired,
  onRequestClose: PropTypes.func.isRequired
};

export default TermsModal;
