import React from "react";
import { FormattedMessage } from "react-intl";
import VirtualContent from "./VirtualContent";

const PromocodeCheckoutDetail = ({ virtualContent }) => {
  let content;

  if (typeof virtualContent === "undefined" || virtualContent.length === 0) {
    content = (
      <FormattedMessage id="promocode" defaultMessage="Promocode redeemed!" />
    );
  } else {
    content = <VirtualContent content={virtualContent} />;
  }

  return (
    <div className="order-detail">
      <div>{content}</div>
    </div>
  );
};

export default PromocodeCheckoutDetail;
