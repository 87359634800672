import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { isEmpty } from "../../utils/validators";

import ResultPage from "./ResultPage";

import "./ResultPage.css";

class PurchaseResultRedirect extends React.Component {
  componentDidMount() {
    const params = this.getQueryParams();

    if (!params.purchaseDetails) {
      window.top.postMessage(
        { type: `checkout-${this.props.match.params.result}` },
        "*"
      );
    }
  }

  shouldShowSidePanel() {
    return !!this.props.location.state || !!this.props.location.search || !!this.getPurchaseDetails();
  }

  getIsGiftValueOrNull() {   
    //Casting the value of isGift, which is a string, as a Boolean will result in a truthy even though the value could be false
    //Alternatively, if the value of isGift is "", undefined, or null, using JSON.parse will result in an error.
    return sessionStorage.getItem("isGift") === "false" ? null : sessionStorage.getItem("isGift");
  }

  getLoadingSpinner() {
    return (
      <div className="container loading">
        <div className="loading-spinner" />
      </div>
    );
  }

  getQueryParams = () => {
    return queryString.parse(this.props.location.search);
  };

  getPurchaseDetails() {
    let purchaseDetails = this.getQueryParams();

    if (!isEmpty(purchaseDetails)) {
      sessionStorage.setItem("purchaseErrorOrPendingDetails", JSON.stringify(purchaseDetails));
      return purchaseDetails;
    }

    if (sessionStorage.getItem("successPurchaseDetails")) {
      return sessionStorage.successPurchaseDetails;
    }

    return undefined;
  }

  render() {
    return (
      <div>
        {this.shouldShowSidePanel() ? (
          <ResultPage
            isGift={this.getIsGiftValueOrNull()}
            purchaseDetails={this.getPurchaseDetails()}
            resultCode={this.props.match.params.result}
          />
        ) : (
          this.getLoadingSpinner()
        )}
      </div>
    );
  }
}

PurchaseResultRedirect.propTypes = {
  isGift: PropTypes.bool,
  purchaseDetails: PropTypes.object
};

export default PurchaseResultRedirect;
