import { CONFIRM_PREPAID_PP_FAIL } from "./paymentOptions";

const RESET_ERROR_MESSAGE = "pmc-client/errors/RESET_ERROR_MESSAGE";

export const errorReasonEnum = (Object.freeze || Object)({
  AUTH_ERROR: 1,
  VELOCITY_ERROR: 2,
  CODE_NOT_FOUND_ERROR: 3,
  CONSENT_REQUIRED: 4,
  CONSENT_INVALID: 5,
  PAYMENT_METHOD_NOT_ALLOWED: 6,
  KR_NATIONAL_ID_REQUIRED: 7,

  isDefined: value =>
    typeof value === "number" &&
    isFinite(value) &&
    // is whole number
    value % 1 === 0 &&
    // is in range
    value >= 1 &&
    value <= 7
});

export default (state = null, { type, error, httpStatus, errorCode }) => {
  if (type === RESET_ERROR_MESSAGE) {
    return null;
  }

  // 404 is not an error but a code not found, 429 is velocity check
  if (
    type === CONFIRM_PREPAID_PP_FAIL &&
    (httpStatus === 404 || httpStatus === 429)
  ) {
    return null;
  }

  if (error) {
    // we need to distinguish between prepaid and non-prepaid errors because we display them differently
    let errorReason = -1; // unknown error reason
    if (httpStatus === 401) {
      switch (errorCode) {
        // PMC-Edge returns 'consent' in the errorCode field
        // when consent registration is required, otherwise default to auth error.
        case "consent":
          errorReason = errorReasonEnum.CONSENT_REQUIRED;
          break;
        case "consent_invalid":
          errorReason = errorReasonEnum.CONSENT_INVALID;
          break;
        case "invalid":
        case "expired":
        default:
          errorReason = errorReasonEnum.AUTH_ERROR;
          break;
      }
    } else if (
      httpStatus === 403 &&
      errorCode === "PAYMENT_METHOD_NOT_ALLOWED"
    ) {
      errorReason = errorReasonEnum.PAYMENT_METHOD_NOT_ALLOWED;
    } else if (httpStatus === 429) {
      errorReason = errorReasonEnum.VELOCITY_ERROR;
    } else if (httpStatus === 404) {
      errorReason = errorReasonEnum.CODE_NOT_FOUND_ERROR;
    } else if (httpStatus === 451 && errorCode === "KR_NATIONAL_ID_REQUIRED") {
      // See https://en.wikipedia.org/wiki/HTTP_451
      errorReason = errorReasonEnum.KR_NATIONAL_ID_REQUIRED;
    }
    return { error, errorReason };
  }

  return state;
};

// Resets the currently visible error message.
export function resetErrorMessage() {
  return {
    type: RESET_ERROR_MESSAGE
  };
}

export function getError(state) {
  return state.error && state.error.error;
}

export function getErrorReason(state) {
  return state.error ? state.error.errorReason : null;
}
