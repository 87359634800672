import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { FormattedMessage } from "react-intl";

import PurchaseHeader from "./PurchaseHeader";

import "./ResultPage.css";

const PurchaseResultFailed = ({
  errorCode, 
  errorMessage, 
  virtualCurrencyCode, 
  storeCode
 }, { intl }) => (
  <div className="right-side">
    <Helmet>
      <title>{intl.formatMessage({ id: "kr_error_occurred" })}</title>
    </Helmet>
    <div className="content">
      <PurchaseHeader
        virtualCurrencyCode={virtualCurrencyCode}
        storeCode={storeCode}
      />

      <div className="kr-results">
        <div className="kr-result-header">
          <p>
            <FormattedMessage
              id="kr_payment_in_progress"
              defaultMessage="결제 진행중"
            />
            <br />
            <span className="red">
              <FormattedMessage
                id="kr_error_occurred"
                defaultMessage="오류가 발생했습니다"
              />
            </span>
          </p>
          <p className="more-info">
            <FormattedMessage
              id="kr_more_info_payment_error"
              defaultMessage="오류 정보에 대한 자세한 내용을 확인하세요."
            />
          </p>
        </div>
        <div className="kr-result-info">
          <h4>
            <FormattedMessage id="kr_error_info" defaultMessage="오류 정보" />
          </h4>
          <table
            cellPadding="0"
            cellSpacing="0"
            summary="결제일 결제수단 결제금액"
          >
            <tbody>
              {errorCode && (
                <tr>
                  <th scope="row">
                    <FormattedMessage
                      id="kr_error_code"
                      defaultMessage="오류코드"
                    />
                  </th>
                  <td>{errorCode}</td>
                </tr>
              )}
              <tr className="red">
                <th scope="row">
                  <FormattedMessage
                    id="kr_error_content"
                    defaultMessage="오류내용"
                  />
                </th>
                <td>{errorMessage}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="bottom-line" />
    </div>
  </div>
);

PurchaseResultFailed.contextTypes = {
  intl: PropTypes.object.isRequired
};

PurchaseResultFailed.propTypes = {
  errorCode: PropTypes.string,
  errorMessage: PropTypes.string.isRequired,
  virtualCurrencyCode: PropTypes.string,
  storeCode: PropTypes.string
};

export default PurchaseResultFailed;
