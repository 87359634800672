import React from "react";
import "./PurchasePayButton.css";

const PurchasePayButton = ({ onClick, allowClick }) => {
  const handleClick = () => {
    if (allowClick) {
      onClick();
    }
  };

  return (
    <div className={allowClick ? "btn-pay" : "btn-pay btn-pay-disabled"}>
      <span>
        <a href="#/" onClick={handleClick}>
          결재
        </a>
      </span>
    </div>
  );
};

export default PurchasePayButton;
