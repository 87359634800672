const GLOBAL_CSS_THEME_LINK_ID = "globalCssThemeLink";

export const DEFAULT_THEME = "lol";

export function applyGlobalCssTheme(cssTheme) {
  if (!cssTheme) {
    return;
  }

  // If you need to debug this locally, change the theme URL in .env.development
  const themeUrl = process.env.REACT_APP_THEMES_URL + `/${cssTheme}.css`;
  let link = document.getElementById(GLOBAL_CSS_THEME_LINK_ID);

  if (link) {
    if (link.getAttribute("href") !== themeUrl) {
      link.setAttribute("href", themeUrl);

      // IE 11 support hack (other browsers don't need this): changing the 'href' attribute for an existing link element
      // doesn't trigger re-rendering in IE 11. The workaround is to remove the node and re-add it.
      // This way it works in all browsers.
      link.parentNode.removeChild(link);
      document.getElementsByTagName("head")[0].appendChild(link);
    }
  } else {
    link = document.createElement("link");
    link.setAttribute("id", GLOBAL_CSS_THEME_LINK_ID);
    link.setAttribute("rel", "stylesheet");
    link.setAttribute("type", "text/css");
    link.setAttribute("href", themeUrl);
    document.getElementsByTagName("head")[0].appendChild(link);
  }
}
