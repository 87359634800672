import PropTypes from "prop-types";
import React from "react";
import PaymentMethodItem from "./PaymentMethodItem";

class PaymentMethodList extends React.Component {
  render() {
    const {
      paymentOptions,
      platform,
      country,
      selectedPaymentMethodId
    } = this.props;
    const { intl: { locale } } = this.context;
    return (
      <div className="payment-method-options">
        <ul>
          {paymentOptions.map((option, index) => (
            <PaymentMethodItem
              key={index}
              onPaymentMethodSelect={this.props.onPaymentMethodSelect}
              selected={selectedPaymentMethodId === option.uniquePaymentMethodId}
              paymentMethodId={option.paymentMethodId}
              uniquePaymentMethodId={option.uniquePaymentMethodId}
              platform={platform}
              country={country}
              locale={locale}
              currency={option.pricePoints[0].realCurrencyCode.toLowerCase()}
            />
          ))}
        </ul>
      </div>
    );
  }
}

PaymentMethodList.contextTypes = {
  intl: PropTypes.object.isRequired
};

PaymentMethodList.propTypes = {
  paymentOptions: PropTypes.array.isRequired,
  onPaymentMethodSelect: PropTypes.func.isRequired,
  country: PropTypes.object.isRequired,
  platform: PropTypes.string.isRequired,
  selectedPaymentMethodId: PropTypes.string
};

export default PaymentMethodList;
