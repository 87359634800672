import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { injectIntl, intlShape } from "react-intl";

import ConsentForm from "../components/kr/ConsentForm";
import ConsentLeftPanel from "../components/kr/ConsentLeftPanel";
import ResultPage from "../components/kr/ResultPage";

import {
  getIsConfirmed,
  confirmConsentRegistration,
  confirmConsentRegistrationV2
} from "../redux/modules/purchaseConsent";
import {getSessionUseApiV2} from "../redux/modules/session";
import {
  getPurchaseContext,
  isContextLoaded
} from "../redux/modules/purchaseContext";
import { resetErrorMessage } from "../redux/modules/error";

import "./PurchaseConsent.css";

import * as Sentry from "@sentry/browser";

import { identityServiceStart } from "@riotgames/kr-identity-sdk";

class PurchaseConsent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      monthSelected: false,
      monthLength: 0,
      agreementChecked: false
    };
  }

  onMonthSelect = length => {
    this.setState({ monthSelected: true, monthLength: length });
  };

  onAgreementCheck = isChecked => {
    this.setState({ agreementChecked: isChecked });
  };

  onRegisterClick = () => {
    if (this.validate()) {
      identityServiceStart(
          (this.props.storeCode && this.props.storeCode === "lor") ? "LOR_PAYMENTS_PARENTAL_CONSENT" : "PAYMENTS_PARENTAL_CONSENT",
        identityData => {
          if (this.props.useApiV2) {
            this.props.confirmConsentRegistrationV2(
              this.state.monthLength,
              identityData.identityToken
            );
          } else {
            this.props.confirmConsentRegistration(
              this.state.monthLength,
              identityData.identityToken
            );
          }
        },
        () => {
          Sentry.captureMessage(
            "KR Identity Service encountered an error during parental consent registration."
          );
        },
        {
          environment: process.env.REACT_APP_SELF_AUTH_ENV
        }
      );
    }
  };

  gotoPaymentOptionsHandler = () => {
    this.props.resetErrorMessage();
    this.context.router.history.push("/");
  };

  getFormattedConsentExpirationDate = () => {
    const now = new Date();
    now.setMonth(now.getMonth() + Number(this.state.monthLength || 0));

    return `${now.getFullYear()}년 ${now.getMonth() + 1}월 ${now.getDate()}일`;
  };

  validate = () => {
    if (!this.state.monthSelected) {
      alert(
        this.props.intl.formatMessage({
          id: "kr_error_select_period"
        })
      );

      return false;
    }

    if (!this.state.agreementChecked) {
      alert(
        this.props.intl.formatMessage({
          id: "kr_error_accept_terms_condition"
        })
      );

      return false;
    }

    return true;
  };

  render() {
    const { isContextLoaded } = this.props;
    if (this.props.confirmed) {
      return (
        <ResultPage
          isGift={false}
          purchaseDetails={{
            formattedDate: this.getFormattedConsentExpirationDate(),
            gotoPaymentOptionsHandler: this.gotoPaymentOptionsHandler
          }}
          resultCode={"consent-confirmed"}
        />
      );
    }

    return (
      <div className="kr-purchase-consent">
        <Helmet bodyAttributes={{ style: "background-color : #fff" }}>
          <title>
            {this.props.intl.formatMessage({
              id: "kr_minor_billing_agreement"
            })}
          </title>
        </Helmet>
        {isContextLoaded ? (
          <div className="kr-purchase-consent-container">
            <ConsentLeftPanel
              isGift={(this.props.context && this.props.context.gifteeSummonerName) || false}
              summonerName={this.props.context.summonerName}
            />
            <ConsentForm
              onAgreementCheck={this.onAgreementCheck}
              onMonthSelect={this.onMonthSelect}
              onRegisterClick={this.onRegisterClick}
            />
          </div>
        ) : (
          <div className="container loading">
            <div className="loading-spinner" />
          </div>
        )}
      </div>
    );
  }
}

PurchaseConsent.propTypes = {
  intl: intlShape.isRequired,
  confirmed: PropTypes.bool.isRequired,
  context: PropTypes.object,
  isContextLoaded: PropTypes.bool.isRequired,
  confirmConsentRegistration: PropTypes.func.isRequired,
  confirmConsentRegistrationV2: PropTypes.func.isRequired,
  resetErrorMessage: PropTypes.func.isRequired
};

PurchaseConsent.contextTypes = {
  router: PropTypes.object
};

const mapStateToProps = state => ({
  confirmed: getIsConfirmed(state),
  isContextLoaded: isContextLoaded(state),
  context: getPurchaseContext(state),
  useApiV2: getSessionUseApiV2(state)
});

export default connect(
  mapStateToProps,
  {
    confirmConsentRegistration,
    confirmConsentRegistrationV2,
    resetErrorMessage
  }
)(injectIntl(PurchaseConsent));
