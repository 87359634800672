import PropTypes from "prop-types";
import React from "react";
import Modal from "react-modal";
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
  intlShape
} from "react-intl";

const styles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  }
};

class PrepaidFailureModal extends React.Component {
  getMessageId = (code, invalidReasonCode) => {
    const unknownError = "prepaid-failure-UNKNOWN";
    switch (code) {
      case "VELOCITY":
      case "NOTFOUND":
      case "DEACTIVATED":
      case "REDEEMED":
      case "EXPIRED":
        return `prepaid-failure-${code}`;
      case "INVALID":
        switch (invalidReasonCode) {
          case "AMOUNT_OR_CURRENCY":
          case "CPF":
          case "DATE_OF_BIRTH":
          case "REGION":
          case "COUNTRY":
          case "FIRST_NAME":
          case "LAST_NAME":
          case "AGE":
            return `prepaid-failure-${code}_${invalidReasonCode}`;
          default:
            return unknownError;
        }
      case "UNKNOWN":
      default:
        return unknownError;
    }
  };

  render() {
    const {
      open,
      failureCode,
      invalidReasonCode,
      intl,
      clearPrepaidResults
    } = this.props;
    const supportErrorLink = intl.formatMessage({ id: "support_error_link" });
    return (
      <Modal
        contentLabel="prepaid-failure"
        className="prepaid-failure-modal"
        isOpen={open}
        style={styles}
      >
        <div className="error-box">
          <div className="error-text">
            <FormattedHTMLMessage
              id={this.getMessageId(failureCode, invalidReasonCode)}
              defaultMessage="Unknown error."
              values={{
                link: supportErrorLink
              }}
            />
          </div>
          <button className="btn btn-default" onClick={clearPrepaidResults}>
            <FormattedMessage id="ok" defaultMessage="OK" />
          </button>
        </div>
      </Modal>
    );
  }
}

PrepaidFailureModal.contextTypes = {
  router: PropTypes.object
};

PrepaidFailureModal.propTypes = {
  open: PropTypes.bool.isRequired,
  failureCode: PropTypes.string,
  invalidReasonCode: PropTypes.string,
  clearPrepaidResults: PropTypes.func,
  intl: intlShape.isRequired
};

export default injectIntl(PrepaidFailureModal);
