import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { getAuthToken } from "../redux/modules/user";
import createLogger from "../utils/logger";
import Modal from "react-modal";
import { withRouter } from "react-router-dom";
import {
  getError,
  getErrorReason,
  errorReasonEnum
} from "../redux/modules/error";
import ErrorHandler from "./ErrorHandler";
import { getSessionIsKorea } from "../redux/modules/session";
import KrErrorHandler from "../components/kr/KrErrorHandler";

class App extends React.Component {
  componentDidMount() {
    Modal.setAppElement(".app-main");
  }

  getChildContext() {
    return {
      logger: createLogger(this.context.apiClient, this.props.token)
    };
  }

  getAppContainer(children) {
    return <div className="app-main">{children}</div>;
  }

  render() {
    const { isKorea, error, errorReason, children } = this.props;

    // This is for handling payment errors - for client errors, see ErrorBoundary
    if (error) {
      if (isKorea && errorReason === errorReasonEnum.VELOCITY_ERROR) {
        return this.getAppContainer(children);
      }

      if (isKorea) {
        return <KrErrorHandler errorReason={errorReason} />;
      }

      return <ErrorHandler errorReason={errorReason} />;
    }

    return this.getAppContainer(children);
  }
}

App.propTypes = {
  children: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  isKorea: PropTypes.bool.isRequired,
  error: PropTypes.string,
  errorReason: PropTypes.number
};

App.contextTypes = {
  apiClient: PropTypes.object.isRequired
};

App.childContextTypes = {
  logger: PropTypes.object.isRequired
};

// Because App component is not rendered via Route and when used with redux blocks the updates
// to fix we need to use withRouter https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md
export default withRouter(
  connect(state => ({
    token: getAuthToken(state),
    isKorea: getSessionIsKorea(state),
    error: getError(state),
    errorReason: getErrorReason(state)
  }))(App)
);
