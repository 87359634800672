import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import "./PurchasePricePoint.css";

import { formatNumberWithCommas } from "../../utils/formatters";

class PurchasePricePoint extends React.Component {
  getClassName() {
    const baseClassName = "price-point";

    if (!this.props.isEnabled) {
      return `${baseClassName} disabled`;
    }

    if (this.props.isActive) {
      return `${baseClassName} active`;
    }

    return baseClassName;
  }

  getOnClickHandler() {
    if (this.props.isEnabled) {
      return () => this.props.onClick(this.props.pricePoint);
    }
  }

  getBonusPercent() {
    const { virtualAmount, virtualBonusAmount } = this.props.pricePoint;
    return Math.round((virtualBonusAmount / virtualAmount) * 100);
  }

  render() {
    const { virtualAmount, virtualBonusAmount, virtualCurrencyCode } = this.props.pricePoint;

    return (
      <div className={this.getClassName()} onClick={this.getOnClickHandler()}>
        <div className="rp">
          <span className="amount">
            {formatNumberWithCommas(virtualAmount)}
          </span>
          <span className="name">
            <FormattedMessage
              id={`vc_code_${virtualCurrencyCode}`.toLowerCase()}
              defaultMessage={virtualCurrencyCode}
          />
          </span>
        </div>
        <div className="price">
          <span>
            {formatNumberWithCommas(
              this.props.pricePoint.realAmountCents / 100
            )}
            원
          </span>
        </div>
        {virtualBonusAmount > 0 && (
          <div className="bonus">
            <div className="bonus-rp">
              <span className="bonus-amount">
                +{formatNumberWithCommas(virtualBonusAmount)}
              </span>
              <span className="bonus-type">
                &nbsp;
                <FormattedMessage
                  id={`vc_code_${virtualCurrencyCode}`.toLowerCase()}
                  defaultMessage={virtualCurrencyCode}
                />
              </span>
            </div>
            <div>
              <span className="bonus-name">{`${this.getBonusPercent()}% BONUS`}</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

PurchasePricePoint.propTypes = {
  isActive: PropTypes.bool,
  isEnabled: PropTypes.bool,
  pricePoint: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

export default PurchasePricePoint;
