import PropTypes from "prop-types";
import React from "react";

const ModalCloseButton = ({ onClick }) => (
  <div className="modal-close" onClick={onClick}>
    <div className="contents">
      <div className="x-icon" />
    </div>
  </div>
);

ModalCloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default ModalCloseButton;
