import React from "react";
import "./MakeAnotherPurchaseButton.css";

const MakeAnotherPurchaseButton = ({ onClick }) => (
  <div className="btn-make-another-purchase">
    <span>
      <a href="#/" onClick={onClick}>
        추가 구매하기
      </a>
    </span>
  </div>
);

export default MakeAnotherPurchaseButton;
