import React from "react";
import { Redirect } from "react-router-dom";

class KrPaymentCancelled extends React.Component {
  // Pay1q iframe contains a cancel button which redirects to /kr/checkout-cancelled instead of closing the modal
  // This will close the modal and return the player to the whitepage PMC
  componentDidMount() {
    window.parent.parent.postMessage({type: "checkout-cancelled"}, "*");
  }
  
  render() {
    return <Redirect to="/" />;
  }
}

export default KrPaymentCancelled;
