import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import PricePointList from "../../components/PricePointList";

import {
  getPaymentOptionsView,
  getMinVirtualAmount,
  getSelectedPaymentMethodId,
  getSelectedPricePointId
} from "../../redux/modules/paymentOptions";
import { getSessionStoreCode, getSessionIsNewFlow } from "../../redux/modules/session";

export const getPricePointsForPaymentMethod = (paymentOptions, paymentMethodId) => {
  return paymentOptions
    .filter((option) => option.uniquePaymentMethodId === paymentMethodId)
    .flatMap((option) => option.pricePoints)
    .filter((pricePoint) => !!pricePoint.virtualCurrencyCode); // SKU price points not supported for selection
};

// Reusable price point list wrapper for the v2 flow
class PricePointView extends React.Component {
  getDoubleBonusVC = (paymentMethodId) => {
    return this.props.paymentOptions.find((option) => option.uniquePaymentMethodId === paymentMethodId)
      ?.hasDoubleBonusVc;
  };

  render() {
    const {
      onPricePointClick,
      attemptStartPurchase,
      paymentOptions,
      selectedPaymentMethodId,
      selectedPricePointId,
      storeCode,
      minVirtualAmount,
      isNewFlow
    } = this.props;

    return (
      <PricePointList
        pricePoints={getPricePointsForPaymentMethod(paymentOptions, selectedPaymentMethodId)}
        onPricePointClick={onPricePointClick}
        selectedPurchasePricePointId={selectedPricePointId}
        canStartPurchase={true}
        attemptStartPurchase={attemptStartPurchase}
        hasDoubleBonusVc={this.getDoubleBonusVC(selectedPaymentMethodId) ? true : false}
        storeCode={storeCode}
        minVirtualAmount={minVirtualAmount}
        isNewFlow={isNewFlow}
      />
    );
  }
}

PricePointView.propTypes = {
  onPricePointClick: PropTypes.func.isRequired,
  attemptStartPurchase: PropTypes.func.isRequired,

  paymentOptions: PropTypes.array.isRequired,
  selectedPaymentMethodId: PropTypes.string,
  selectedPricePointId: PropTypes.string,
  storeCode: PropTypes.string,
  minVirtualAmount: PropTypes.number,
  isNewFlow: PropTypes.bool
};

const mapStateToProps = (state) => ({
  paymentOptions: getPaymentOptionsView(state),
  selectedPaymentMethodId: getSelectedPaymentMethodId(state),
  selectedPricePointId: getSelectedPricePointId(state),
  storeCode: getSessionStoreCode(state),
  minVirtualAmount: getMinVirtualAmount(state),
  isNewFlow: getSessionIsNewFlow(state)
});

export default connect(mapStateToProps)(PricePointView);
