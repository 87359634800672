import React, { Component } from "react";
import PropTypes from "prop-types";

class PurchasePaymentMethodTab extends Component {
  onClick = () => {
    const { onClick, tab } = this.props;
    onClick(tab);
  };

  render() {
    const {
      onClick,
      props: { activeTab, label, tab }
    } = this;

    let className = "tab-list-item";

    if (activeTab === tab) {
      className += " tab-list-active";
    }

    return (
      <li className={className} onClick={onClick}>
        <span>{label}</span>
      </li>
    );
  }
}

PurchasePaymentMethodTab.propTypes = {
  activeTab: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default PurchasePaymentMethodTab;
