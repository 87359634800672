import React from "react";
import { FormattedMessage } from "react-intl";

const MakeAnotherPurchaseButton = ({ onClick }) => {
  return (
    <button className="btn btn-default" onClick={onClick}>
      <FormattedMessage
        id="make_another_purchase"
        defaultMessage="Make another purchase"
      />
    </button>
  );
};

export default MakeAnotherPurchaseButton;
