import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";

class CountryChangeList extends React.Component {
  onChangeCountry = country => {
    this.props.onChangeCountry(country);
  };

  render() {
    const { currentCountry, onModalOpen } = this.props;
    return (
      <div className="country-change">
        <FormattedMessage
          id="currently_showing_options"
          defaultMessage="Currently showing payment options for"
        />
        <span style={{ marginRight: 5, marginLeft: 5, whiteSpace:"nowrap" }}>
          {currentCountry.name}
        </span>
        <br />
        <a href="#/" className="link text-highlight" onClick={onModalOpen}>
          <FormattedMessage id="change" defaultMessage="[Change]" />
        </a>
      </div>
    );
  }
}

CountryChangeList.propTypes = {
  currentCountry: PropTypes.object.isRequired,
  onChangeCountry: PropTypes.func.isRequired,
  onModalOpen: PropTypes.func.isRequired
};

export default CountryChangeList;
