import React from "react";
import PropTypes from "prop-types";

import "./CheckoutStartPopupPrepaid.css";
import "./CheckoutStartPopup.css";
import wonImg from "../../static/img/kr/won_symbol.png";
import { injectIntl } from "react-intl";

class CheckoutStartPopupPrepaid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pinCodeSegment1: "",
      pinCodeSegment2: "",
      pinCodeSegment3: ""
    };

    this.textInput1 = React.createRef();
    this.textInput2 = React.createRef();
    this.textInput3 = React.createRef();
  }

  onPrepaidCodeChange = event => {
    const nextInputNumber =
      Number(event.target.name.substring(event.target.name.length - 1)) + 1;

    if (event.target.value.length === 4 && nextInputNumber !== 4) {
      const nextInputName = "textInput" + nextInputNumber;
      this[nextInputName].current.focus();
    }

    this.setState({
      [event.target.name]: event.target.value
    });
  };

  onPrepaidCodeSubmit = () => {
    // No CPF/DoB to submit
    this.props.confirmPricePointPrepaidPurchase(
      this.props.sessionToken,
      this.state.pinCodeSegment1 +
        this.state.pinCodeSegment2 +
        this.state.pinCodeSegment3
    );
  };

  render() {
    const { onFrameClose, virtualCurrencyCode, intl } = this.props;
    const prepaidVc = "편의점 " + intl.formatMessage({ id: `vc_code_${virtualCurrencyCode}`, defaultMessage: `${virtualCurrencyCode}`});
    return (
      <div>
        <div className="kr-checkout-start-background" />
        <div className="kr-checkout-start-modal-overlay prepaid">
          <div className="prepaid-form-header-row">
            <span className="prepaid-form-won-image">
              <img src={wonImg} alt={prepaidVc} />
            </span>
            <span className="prepaid-form-payment-method-name">{prepaidVc}</span>
            <span className="prepaid-form-close-button" onClick={onFrameClose}>
              <span>&times;</span>
            </span>
          </div>

          <div className="prepaid-form-info-row">
            <span className="prepaid-form-info-text">
              {prepaidVc} 핀(PIN)번호를 입력하여 결제하세요.
            </span>
          </div>

          <div className="prepaid-form-form-row">
            <div className="prepaid-form-input-and-help">
              <div className="prepaid-form-input-help-text">
                <span>핀번호 (12자리)</span>
              </div>
              <div className="prepaid-form-inputs">
                <input
                  type="text"
                  name="pinCodeSegment1"
                  value={this.state.pinCodeSegment1}
                  onChange={this.onPrepaidCodeChange}
                  maxLength={4}
                  ref={this.textInput1}
                />
                &nbsp;—&nbsp;
                <input
                  type="text"
                  name="pinCodeSegment2"
                  value={this.state.pinCodeSegment2}
                  onChange={this.onPrepaidCodeChange}
                  maxLength={4}
                  ref={this.textInput2}
                />
                &nbsp;—&nbsp;
                <input
                  type="text"
                  name="pinCodeSegment3"
                  value={this.state.pinCodeSegment3}
                  onChange={this.onPrepaidCodeChange}
                  maxLength={4}
                  ref={this.textInput3}
                />
              </div>
            </div>
            <div className="prepaid-form-submit-button">
              {this.props.loading ? (
                <button className="btn-pay disabled" />
              ) : (
                <button
                  className="btn-pay"
                  onClick={this.onPrepaidCodeSubmit}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CheckoutStartPopupPrepaid.propTypes = {
  confirmPricePointPrepaidPurchase: PropTypes.func.isRequired,
  onFrameClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  virtualCurrencyCode: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired
};

export default injectIntl(CheckoutStartPopupPrepaid);
