import PropTypes from "prop-types";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import App from "./App";
import PaymentOptions from "./PaymentOptions";
import PrepaidMode from "./PrepaidMode";
import PaymentComplete from "./PaymentComplete";
import Start from "./Start";
import PaymentMethodSelection from "./v2/PaymentMethodSelection";
import PaymentCancelled from "../components/PaymentCancelled";
import ExternalWindowOpener from "../containers/ExternalWindowOpener";
import KrPaymentCancelled from "../components/kr/KrPaymentCancelled";
import PaymentFailed from "./PaymentFailed";
import InvalidGiftee from "./InvalidGiftee";
import PurchaseResultRedirect from "../components/kr/PurchaseResultRedirect";
import ErrorBoundary from "../containers/ErrorBoundary";

export default class Root extends React.Component {
  getChildContext() {
    return {
      apiClient: this.props.apiClient
    };
  }

  render() {
    const { store, locale, messages } = this.props;
    return (
      <IntlProvider locale={locale} messages={messages}>
        <Provider store={store}>
          <ErrorBoundary>
            <Router>
              <App>
                <Switch>
                  <Route exact path="/" component={PaymentOptions} />
                  <Route path="/prepaid" component={PrepaidMode} />
                  <Route path="/checkout-success" component={PaymentComplete} />
                  <Route
                    path="/checkout-cancelled"
                    component={PaymentCancelled}
                  />
                  <Route path="/checkout-failed" component={PaymentFailed} />
                  <Route path="/invalid-giftee" component={InvalidGiftee} />
                  <Route path="/start" component={Start} />
                  <Route
                    path="/kr/checkout-:result(success|failed|pending)"
                    component={PurchaseResultRedirect}
                  />
                  <Route
                    path="/kr/checkout-cancelled"
                    component={KrPaymentCancelled}
                  />
                  <Route
                    path="/external-window-open"
                    component={ExternalWindowOpener}
                  />
                  <Route path="/payment-method-selection" component={PaymentMethodSelection} />
                </Switch>
              </App>
            </Router>
          </ErrorBoundary>
        </Provider>
      </IntlProvider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  apiClient: PropTypes.object.isRequired
};

Root.childContextTypes = {
  apiClient: PropTypes.object.isRequired
};
