import React from "react";
import { FormattedMessage } from "react-intl";
import ReturnToStoreButton from "./ReturnToStoreButton";

const PaymentExpired = () => {
  return (
    <div className="expired-page">
      <div className="expired-box">
        <h1>
          <FormattedMessage id="expired" defaultMessage="Expired"/>
        </h1>
        <div className="expired-text">
          <FormattedMessage
            id="payment_expired_text"
            defaultMessage="Your purchase has timed out. Please close this window to start a new purchase."
          />
        </div>
        <ReturnToStoreButton buttonMessage="button_close" buttonMessageDefault="Close" />
      </div>
    </div>
  );
};

export default PaymentExpired;
