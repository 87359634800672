import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import Currency from "./Currency";

const DirectPurchaseConfirm = ({
  directPurchaseAmount,
  directPurchaseCurrency,
  onConfirmClick,
  onCancelClick
}) =>
  <div className="confirm-purchase">
    <div className="confirm-purchase-form">
      <div className="callout">
        <p>
          Please confirm your payment of{" "}
          <strong>
            <Currency
              value={directPurchaseAmount / 100}
              currency={directPurchaseCurrency}
            />
          </strong>
        </p>
      </div>
      <button
        type="submit"
        className="btn btn-primary"
        onClick={onConfirmClick}
      >
        <FormattedMessage id="confirm" defaultMessage="Confirm" />
      </button>
      <button type="submit" className="btn btn-default" onClick={onCancelClick}>
        <FormattedMessage id="cancel" defaultMessage="Cancel" />
      </button>
    </div>
  </div>;

DirectPurchaseConfirm.propTypes = {
  directPurchaseAmount: PropTypes.number,
  directPurchaseCurrency: PropTypes.string,
  onConfirmClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired
};

export default DirectPurchaseConfirm;
