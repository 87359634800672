import React from "react";
import PropTypes from "prop-types";
import CheckoutStartPopupPricePoint from "./CheckoutStartPopupPricePoint";
import CheckoutStartPopupPrepaid from "./CheckoutStartPopupPrepaid";

import "./CheckoutStartPopup.css";

class CheckoutStartPopup extends React.Component {
  render() {
    const {
      onFrameClose,
      checkoutUrl,
      cardCode,
      userEmail,
      paymentMethod,
      virtualCurrencyCode,
      virtualAmount,
      isPrepaid,
      paymentMethodId,
      confirmPricePointPrepaidPurchase,
      sessionToken,
      prepaidSuccess,
      prepaidFailureCode,
      prepaidInvalidReasonCode,
      purchasedPricePoint,
      purchasedPlayerFacingId,
      loading
    } = this.props;
    if (isPrepaid) {
      return (
        <CheckoutStartPopupPrepaid
          onFrameClose={onFrameClose}
          confirmPricePointPrepaidPurchase={confirmPricePointPrepaidPurchase}
          sessionToken={sessionToken}
          prepaidInvalidReasonCode={prepaidInvalidReasonCode}
          prepaidFailureCode={prepaidFailureCode}
          prepaidSuccess={prepaidSuccess}
          purchasedPricePoint={purchasedPricePoint}
          purchasedPlayerFacingId={purchasedPlayerFacingId}
          loading={loading}
          virtualCurrencyCode={virtualCurrencyCode}
        />
      );
    } else {
      return (
        <CheckoutStartPopupPricePoint
          onFrameClose={onFrameClose}
          checkoutUrl={checkoutUrl}
          cardCode={cardCode}
          userEmail={userEmail}
          paymentMethod={paymentMethod}
          virtualAmount={virtualAmount}
          paymentMethodId={paymentMethodId}
        />
      );
    }
  }
}

CheckoutStartPopup.propTypes = {
  checkoutUrl: PropTypes.string,
  onFrameClose: PropTypes.func.isRequired,
  confirmPricePointPrepaidPurchase: PropTypes.func,
  cardCode: PropTypes.string,
  userEmail: PropTypes.string,
  paymentMethod: PropTypes.string,
  virtualCurrencyCode: PropTypes.string.isRequired,
  virtualAmount: PropTypes.number,
  loading: PropTypes.bool
};

export default CheckoutStartPopup;
