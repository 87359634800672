import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import PrepaidFailureModal from "../components/PrepaidFailureModal";
import { prepaidPurchaseInvalid } from "../redux/modules/paymentOptions";
import { isCpfValid, isDateValid, isStringNullOrWhiteSpace } from "../utils/validators";

// Max field length for first and last name fields
const MAX_NAME_FIELD_LENGTH = 48;

class PrepaidCodeWindow extends React.Component {
  state = {
    prepaidCardInput: "",
    buyerCpfInput: null,
    buyerDateOfBirthInput: null,
    buyerFirstNameInput: null,
    buyerLastNameInput: null
  };

  onPrepaidCodeChange = event => {
    this.setState({
      prepaidCardInput: event.target.value
    });
  };

  onBuyerCpfChange = event => {
    this.setState({
      buyerCpfInput: event.target.value
    });
  };

  onBuyerFirstNameChange = event => {
    this.setState({
      buyerFirstNameInput: event.target.value
    });
  };

  onBuyerLastNameChange = event => {
    this.setState({
      buyerLastNameInput: event.target.value
    });
  };

  onBuyerDateOfBirthChange = event => {
    this.setState({
      buyerDateOfBirthInput: event.target.value
    });
  };

  onPrepaidCodeSubmit = () => {
    const validationError = this.validateInputFields();

    if (validationError) {
      this.props.prepaidPurchaseInvalid(
        validationError.failureCode,
        validationError.invalidReasonCode
      );
      return;
    }

    this.props.confirmPricePointPrepaidPurchase(
      this.props.sessionToken,
      this.state.prepaidCardInput,
      this.state.buyerCpfInput,
      this.state.buyerDateOfBirthInput,
      this.state.buyerFirstNameInput,
      this.state.buyerLastNameInput
    );
  };

  validateInputFields = () => {
    if (this.isCpfForm()) {
      if (!isCpfValid(this.state.buyerCpfInput)) {
        return { failureCode: "INVALID", invalidReasonCode: "CPF" };
      }

      const firstName = this.state.buyerFirstNameInput;
      if (
        isStringNullOrWhiteSpace(firstName) ||
        firstName.length > MAX_NAME_FIELD_LENGTH
      ) {
        return { failureCode: "INVALID", invalidReasonCode: "FIRST_NAME" };
      }

      const lastName = this.state.buyerLastNameInput;
      if (
        isStringNullOrWhiteSpace(lastName) ||
        lastName.length > MAX_NAME_FIELD_LENGTH
      ) {
        return { failureCode: "INVALID", invalidReasonCode: "LAST_NAME" };
      }

      if (!isDateValid(this.state.buyerDateOfBirthInput)) {
        return { failureCode: "INVALID", invalidReasonCode: "DATE_OF_BIRTH" };
      }
    }
  };

  isCpfForm = () => {
    const isLoL = this.props.game === "lol";
    const countryCode = this.props.country.code3 || "";
    return (
      (isLoL &&
        countryCode.toUpperCase() === "BRA" &&
        this.props.platform.toUpperCase() === "BR1") ||
      (!isLoL && countryCode.toUpperCase() === "BRA")
    );
  };

  renderCpfForm = () => {
    const { formatMessage } = this.context.intl;
    const dobPlaceholder = formatMessage({
      id: "date_of_birth_placeholder",
      defaultMessage: "dd/mm/yyyy"
    });

    return (
      <div>
        <div className="first-and-last-name-row">
          <div className="first-name-container">
            <h4>
                    <FormattedMessage
                      id="first_name"
                      defaultMessage="First Name"
                    />
            </h4>
            <input
              className="input"
              type="text"
              value={this.state.buyerFirstNameInput}
              onChange={this.onBuyerFirstNameChange}
              maxLength={MAX_NAME_FIELD_LENGTH}
            />
          </div>
          <div className="last-name-container">
            <h4>
                    <FormattedMessage
                      id="last_name"
                      defaultMessage="Last Name"
                    />
            </h4>
            <input
              className="input"
              type="text"
              value={this.state.buyerLastNameInput}
              onChange={this.onBuyerLastNameChange}
              maxLength={MAX_NAME_FIELD_LENGTH}
            />
          </div>
        </div>
        <div className="cpf-and-dob-name-row">
          <div className="cpf-container">
            <h4>
              <FormattedMessage id="cpf" defaultMessage="CPF" />
            </h4>
            <InputMask
              className="input"
              type="text"
              mask="999.999.999-99"
                    formatChars={{ "9": "[0-9]" }}
              alwaysShowMask={false}
              placeholder="999.999.999-99"
              value={this.state.buyerCpfInput}
              onChange={this.onBuyerCpfChange}
            />
          </div>
          <div className="dob-container">
            <h4>
                    <FormattedMessage
                      id="date_of_birth"
                      defaultMessage="Date of Birth"
                    />
            </h4>
            <InputMask
              className="input"
              type="text"
              mask={dobPlaceholder}
              formatChars={{
                a: "[0-9]",
                d: "[0-9]",
                m: "[0-9]",
                y: "[0-9]"
              }}
              alwaysShowMask={false}
              placeholder={dobPlaceholder}
              value={this.state.buyerDateOfBirthInput}
              onChange={this.onBuyerDateOfBirthChange}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { prepaidFailureCode, prepaidInvalidReasonCode, clearPrepaidResults } = this.props;

    return (
      <div className={`prepaid-code ${this.isCpfForm() ? "cpf" : ""}`}>
        <h2>
          <FormattedMessage id="prepaid_cards_and_codes" defaultMessage="Prepaid cards and codes" />
        </h2>
        <p>
          <FormattedMessage
            id="prepaid_message"
            defaultMessage="If you have a prepaid card, Riot PIN, or other code, please enter the code below and press submit."
          />
        </p>
        <div className="prepaid-card">
          <div className="code-row">
            {this.isCpfForm() && (
              <h4>
                <FormattedMessage id="prepaid_code" defaultMessage="Code" />
              </h4>
            )}
            <input
              className="input"
              type="text"
              value={this.state.prepaidCardInput}
              onChange={this.onPrepaidCodeChange}
              maxLength="50"
            />
          </div>
          {this.isCpfForm() && this.renderCpfForm()}
          <button className="btn btn-default" onClick={this.onPrepaidCodeSubmit}>
            <FormattedMessage id="submit" defaultMessage="Submit" />
          </button>
        </div>
        <PrepaidFailureModal
          open={Boolean(prepaidFailureCode)}
          failureCode={prepaidFailureCode}
          invalidReasonCode={prepaidInvalidReasonCode}
          clearPrepaidResults={clearPrepaidResults}
        />
      </div>
    );
  }
}

PrepaidCodeWindow.contextTypes = {
  intl: PropTypes.object.isRequired
};

PrepaidCodeWindow.propTypes = {
  prepaidFailureCode: PropTypes.string,
  prepaidInvalidReasonCode: PropTypes.string,
  clearPrepaidResults: PropTypes.func.isRequired,
  confirmPricePointPrepaidPurchase: PropTypes.func.isRequired,
  prepaidPurchaseInvalid: PropTypes.func.isRequired,
  sessionToken: PropTypes.string.isRequired,
  country: PropTypes.object.isRequired,
  game: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired
};

export default connect(null, {
  prepaidPurchaseInvalid
})(PrepaidCodeWindow);
