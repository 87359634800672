import React from "react";
import { Redirect } from "react-router-dom";

class PaymentCancelled extends React.Component {
  render() {
    return <Redirect to="/" />;
  }
}

export default PaymentCancelled;
