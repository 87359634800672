import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { FormattedMessage } from "react-intl";

import PurchaseHeader from "./PurchaseHeader";
import MakeAnotherPurchaseButton from "./MakeAnotherPurchaseButton";

import { formatNumberWithCommas } from "../../utils/formatters";

import "./ResultPage.css";

const PurchaseResultSuccess = (
  {
    paymentDate,
    paymentMethod,
    virtualAmount,
    currencyAmount,
    startNewPurchase,
    useApiV2,
    virtualCurrencyCode,
    storeCode
  },
  { intl }
) => (
  <div className="right-side">
    <Helmet>
      <title>{intl.formatMessage({ id: "kr_charge_complete_1" })}</title>
    </Helmet>
    <div className="content">
      <PurchaseHeader
        virtualCurrencyCode={virtualCurrencyCode}
        storeCode={storeCode}
      />

      <div className="kr-results">
        <div className="kr-result-header">
          <p>
            <span className="big">{formatNumberWithCommas(virtualAmount)}</span>{" "}
            <FormattedMessage
                id={`vc_code_${virtualCurrencyCode}`.toLowerCase()}
                defaultMessage={virtualCurrencyCode}
            />
            <br />
            <span className="red">
              <FormattedMessage
                id="kr_charge_complete_1"
                defaultMessage="결제 완료"
              />
            </span>
            <FormattedMessage
              id="kr_charge_complete_2"
              defaultMessage=" 되었습니다."
            />
          </p>
          <p className="more-info">
            <FormattedMessage
              id="kr_more_info_payment_success_1"
              defaultMessage="어래 결제 금액 및 {vc_name} 충전 내역을 확인하세요."
              values={{
                vc_name: (
                  <FormattedMessage
                      id={`vc_code_${virtualCurrencyCode}`.toLowerCase()}
                      defaultMessage={virtualCurrencyCode}
                  />
                )
              }}
            />
            <br />
            <FormattedMessage
              id="kr_more_info_payment_success_2"
              defaultMessage="결제 완료 내역이 이메일로 발송되었습니다."
            />
          </p>
        </div>

        {useApiV2 && (
          <div>
            <MakeAnotherPurchaseButton onClick={startNewPurchase} />
          </div>
        )}

        <div className="kr-result-info">
          <h4>
            <FormattedMessage
              id="kr_payment_details"
              defaultMessage="결제 내역"
            />
          </h4>
          <table
            cellPadding="0"
            cellSpacing="0"
            summary="결제일 결제수단 결제금액"
          >
            <tbody>
              <tr>
                <th scope="row">
                  <FormattedMessage
                    id="kr_payment_date"
                    defaultMessage="결제일"
                  />
                </th>
                <td>{paymentDate}</td>
              </tr>
              <tr>
                <th scope="row">
                  <FormattedMessage
                    id="kr_payment_method"
                    defaultMessage="결제수단"
                  />
                </th>
                <td>
                  <FormattedMessage
                      id={paymentMethodMapper(paymentMethod)}
                      defaultMessage={paymentMethod}
                      />
                </td>
              </tr>
              <tr className="red">
                <th scope="row">
                  <FormattedMessage
                    id="kr_payment_amount"
                    defaultMessage="결제금액"
                  />
                </th>
                <td>{formatNumberWithCommas(currencyAmount)}원</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="bottom-line" />
    </div>
  </div>
);

PurchaseResultSuccess.contextTypes = {
  intl: PropTypes.object.isRequired
};

PurchaseResultSuccess.propTypes = {
  paymentDate: PropTypes.string.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  virtualAmount: PropTypes.number.isRequired,
  currencyAmount: PropTypes.number.isRequired,
  useApiV2: PropTypes.bool.isRequired,
  virtualCurrencyCode: PropTypes.string.isRequired,
  storeCode: PropTypes.string.isRequired
};

function paymentMethodMapper(paymentMethod) {
  if (paymentMethodMap.hasOwnProperty(paymentMethod)) {
    return paymentMethodMap[paymentMethod];
  } else {
    return paymentMethod;
  }
}

const paymentMethodMap = {
  "toss-creditcard": "kr_purchase_result_generic_method_creditcard",
  "toss-brandpaycard": "kr_purchase_result_generic_method_simplecard",
  "toss-brandpaybank": "kr_purchase_result_generic_method_simplebank",
  "toss-tosspay": "kr_purchase_result_generic_method_tosspay",
  "pay1q-ktfc": "kr_purchase_result_generic_method_bank",
  "간편 계좌 이체": "kr_purchase_result_generic_method_simplebank",
  "간편 카드 결제": "kr_purchase_result_generic_method_simplecard",
  "naverpay": "kr_purchase_result_generic_method_naverpay"
}

export default PurchaseResultSuccess;
