import { combineReducers } from "redux";
import user from "./user";
import session from "./session";
import paymentOptions from "./paymentOptions";
import checkout from "./checkout";
import error from "./error";
import purchaseConsent from "./purchaseConsent";
import purchaseContext from "./purchaseContext";

export default combineReducers({
  user,
  session,
  paymentOptions,
  checkout,
  error,
  purchaseConsent,
  purchaseContext
});
