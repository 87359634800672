import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import ModalDialogLink from "../components/ModalDialogLink";

import { getCurrentCountry } from "../redux/modules/user";

class PaymentOptionsFooter extends React.Component {
  render() {
    const {
      intl: { locale }
    } = this.context;

    const termsOfService = (
      <a
        href={this.context.intl.formatMessage({
          id: "terms_of_service_link",
          defaultMessage: "https://www.riotgames.com/en/terms-of-service"
        })}
        target="_blank"
        rel="noopener noreferrer"
        className="link text-highlight"
      >
            <span>
              <FormattedMessage
                id="terms_of_service"
                defaultMessage="Terms of Service"
              />
            </span>
      </a>);

    let bySelectingYouAgreeTo = "";
    if (this.props.isDirectPurchase) {
      bySelectingYouAgreeTo = (
        <FormattedMessage
          id="by_selecting_you_agree"
          defaultMessage="By selecting 'confirm', you agree to the"
          values={{
            selection: (
              <span>
                [<FormattedMessage id="confirm" defaultMessage="confirm" />]
              </span>
            )
          }}
        />
      );
    } else {
      bySelectingYouAgreeTo = (
        <FormattedMessage
          id="by_selecting_you_agree"
          defaultMessage="By selecting an RP? package, you agree to the"
          values={{
            selection: (
              <FormattedMessage
                id="by_selecting_package"
                defaultMessage="an RP? package"
                values={{
                  vc_name: (
                    <FormattedMessage
                      id={`vc_name_${this.props.virtualCurrency}`.toLowerCase()}
                      defaultMessage="points"
                    />
                  )
                }}
              />
            )
          }}
        />
      );
    }

    const refundPolicy = (
      <ModalDialogLink
        linkTextLocId="refund_policy"
        linkTextDefaultMessage="Refund Policy"
        modalTitleLocId="refund_policy"
        modalTitleDefaultMessage="Refund Policy"
        modalContentsFileName="refund"
      />
    );

    // We use a special footer message for Japan
    if (locale === "ja-JP") {
      return (
        <div className="payment-options-footer-jp">
          {bySelectingYouAgreeTo}
          {termsOfService}
          <FormattedMessage id="comma" defaultMessage="," />
          <a
            href={this.context.intl.formatMessage({
              id: `jp_refund_policy_url_${this.props.virtualCurrency}`.toLowerCase()
            })}
            target="_blank"
            rel="noopener noreferrer"
            className="link text-highlight"
          >
            <span>
              <FormattedMessage
                id="refund_policy"
                defaultMessage="Refund Policy"
              />
            </span>
          </a>
          <FormattedMessage id="comma" defaultMessage="," />
          <a
            href="https://www.riotgames.com/ja/payment-check"
            target="_blank"
            rel="noopener noreferrer"
            className="link text-highlight"
          >
            <span>
              <FormattedMessage
                id="jp_payment_check"
                defaultMessage="Payment Services Act"
              />
            </span>
          </a>
          <FormattedMessage id="comma" defaultMessage="," />
          <a
            href="https://www.riotgames.com/ja/commercial-transactions"
            target="_blank"
            rel="noopener noreferrer"
            className="link text-highlight"
          >
            <span>
              <FormattedMessage
                id="jp_commercial_transactions"
                defaultMessage="Specified Commercial Transactions Act"
              />
            </span>
          </a>
        </div>
      );
    }

    if(this.props.currentCountry && (this.props.currentCountry.code3 === "AUS" || this.props.currentCountry.code3 === "NZL")) {
        return (
            <div className="payment-options-footer">
                <div id="payment-options-footer-tos">
                    <FormattedMessage
                        id="oce_refund_policy"
                        defaultMessage="Questions about refunds? Please read our"
                    />
                    &nbsp;
                    {refundPolicy}
                    <FormattedMessage
                        id="oce_before_purchasing"
                        defaultMessage=" before purchasing!"
                    />
                    &nbsp;
                    <FormattedMessage
                        id="oce_additional_rights"
                        defaultMessage="If you live in Australia or New Zealand, you can find additional information on certain rights guaranteed to you under applicable law "
                    />
                    <a
                        href="https://oce.leagueoflegends.com/en-au/legal/consumer-guarantees/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link text-highlight"
                    >
                        <span>
                            <FormattedMessage
                                id="oce_here"
                                defaultMessage="here"
                            />
                        </span>
                    </a>
                    <FormattedMessage
                        id="oce_period"
                        defaultMessage="."
                    />
                    &nbsp;
                    <FormattedMessage
                        id="Applicable_taxes_calculated_at_checkout"
                        defaultMessage="Applicable taxes calculated at checkout"
                    />
                </div>
            </div>
        );
    }

    return (
      <div className="payment-options-footer">
        <div id="payment-options-footer-tos">
          {bySelectingYouAgreeTo}
          &nbsp;
          {termsOfService}
          &nbsp;
          <FormattedMessage id="and" defaultMessage="and" />
          &nbsp;
          {refundPolicy}
          &nbsp;
        </div>
        {this.props.currentCountry && this.props.currentCountry.code3 === "ARG" &&
          (<div className="text-warn">
            <FormattedMessage
              id="arg_vat_message"
              defaultMessage="Due to Argentinian tax laws, banks may collect an additional 21% VAT on sales."
            />
          </div>)}
        {this.props.showTaxDisclaimer && (
          <FormattedMessage
            id="Applicable_taxes_calculated_at_checkout"
            defaultMessage="Applicable taxes calculated at checkout"
          />
        )}
      </div>
    );
  }
}

PaymentOptionsFooter.contextTypes = {
  intl: PropTypes.object.isRequired
};

PaymentOptionsFooter.propTypes = {
  currentCountry: PropTypes.object,
  virtualCurrency: PropTypes.string.isRequired,
  showTaxDisclaimer: PropTypes.bool,
  isDirectPurchase: PropTypes.bool
};

const mapStateToProps = state => ({
  currentCountry: getCurrentCountry(state)
});

export default connect(mapStateToProps)(PaymentOptionsFooter);
