import React from "react";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import "./ConsentFormDescription.css";

class ConsentFormDescription extends React.PureComponent {
  render() {
    const { storeCode, intl } = this.props;

    return (
      <div className="kr-consent-rp-agree-scroll">
        <p>
          <FormattedMessage id="kr_consent_agreement_form_p1" />
        </p>
        <p>
          <FormattedMessage
            id="kr_consent_agreement_form_p2"
            values={{
              link: this.getGameHomePageLink(storeCode)
            }}
          />
        </p>
        <br />
        <p>
          <FormattedMessage id="kr_consent_agreement_form_p3" />
        </p>
        <p>
          <FormattedMessage id="kr_consent_agreement_form_p4" />
        </p>
        <p>
          <FormattedMessage id="kr_consent_agreement_form_p5" />
        </p>
        <p>
          <FormattedMessage
            id="kr_consent_agreement_form_p6"
            values={{
              link: (
                <a
                  href={this.props.intl.formatMessage({ id: `game_vc_policy_link_${storeCode}` })}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ins>정책</ins>
                </a>
              )
            }}
          />
        </p>
        <br />
        <p>
          <FormattedMessage id="kr_consent_agreement_form_p7" />
        </p>
        <p>
          <FormattedMessage
            id="kr_consent_agreement_form_p8"
            values={{
              link: (
                <a
                  href="https://legal.kr.riotgames.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ins>https://legal.kr.riotgames.com/privacy</ins>
                </a>
              )
            }}
          />
        </p>
        <p>
          <FormattedMessage id="kr_consent_agreement_form_p9" />
        </p>
        <p>
          <FormattedMessage id="kr_consent_agreement_form_p10" />
        </p>
        <p>
          <FormattedMessage id="kr_consent_agreement_form_p11" />
        </p>
        <br />
        <p>
          <FormattedMessage id="kr_consent_agreement_form_p12" />
        </p>
        <p>
          <FormattedMessage
            id="kr_consent_agreement_form_p13"
            values={{
              game_name: intl.formatMessage({ id: `game_name_${storeCode}` })
            }}
          />
        </p>
        <p>
          <FormattedMessage
            id="kr_consent_agreement_form_p14"
            values={{
              game_name: intl.formatMessage({ id: `game_name_${storeCode}` }),
              link: this.getGameHomePageLink(storeCode)
            }}
          />
        </p>
        <p>
          <FormattedMessage id="kr_consent_agreement_form_p15" />
        </p>
      </div>
    );
  }

  getGameHomePageLink(game) {
    const { intl } = this.props;
    return (
      <a
        href={intl.formatMessage({ id: `game_link_${game}`})}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ins>{new URL(intl.formatMessage({ id: `game_link_${game}`})).hostname}</ins>
      </a>
    );
  }
}

ConsentFormDescription.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(ConsentFormDescription);
