import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { FormattedMessage } from "react-intl";

import "./ConsentForm.css";
import "./PurchaseConsentConfirmed.css";
import "./ResultPage.css";

const PurchaseConsentConfirmed = (
  { formattedDate, gotoPaymentOptionsHandler },
  { intl }
) => (
  <div className="right-side">
    <Helmet>
      <title>{intl.formatMessage({ id: "kr_consent_confirm_title" })}</title>
    </Helmet>

    <div className="content">
      <div className="kr-consent-disclaimer border-bottom">
        <FormattedMessage id="kr_consent_disclaimer" />
      </div>

      <div className="kr-results">
        <div className="kr-result-header">
          <p>
            <FormattedMessage
              id="kr_purchase_consent_confirm_1"
              defaultMessage="법정대리인의 본인인증이 성공하여"
            />
            <br />
            <span className="red">
              <FormattedMessage
                id="kr_purchase_consent_confirm_2"
                defaultMessage="미성년자 결제동의가 완료되였습니다."
              />
            </span>
          </p>
          <p className="more-info">
            <FormattedMessage
              id="kr_purchase_consent_confirm_3"
              defaultMessage="법정대리인께서는 회원님이 라이엇게임즈 코리아 유한회사의"
            />
            <br />
            <FormattedMessage
              id="kr_purchase_consent_confirm_4"
              defaultMessage="컨텐츠 이용에 따른 요금결제에 동의하셨습니다."
            />
          </p>
          <div className="kr-consent-confirm-date red">
            <FormattedMessage
              id="kr_purchase_consent_date_label"
              defaultMessage="결제동의 만료일"
            />
            &nbsp;:&nbsp;
            {formattedDate}
          </div>
          <button
            className="kr-consent-continue-button"
            onClick={gotoPaymentOptionsHandler}
          >
            <FormattedMessage
              id="kr_new_payment"
              defaultMessage="계속 결제하기"
            />
          </button>
        </div>
      </div>
      <div className="bottom-line" />
    </div>
  </div>
);

PurchaseConsentConfirmed.contextTypes = {
  intl: PropTypes.object.isRequired
};

PurchaseConsentConfirmed.propTypes = {
  formattedDate: PropTypes.string.isRequired,
  gotoPaymentOptionsHandler: PropTypes.func.isRequired
};

export default PurchaseConsentConfirmed;
