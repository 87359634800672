import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import "./KrPaymentOptions.css";

import CheckoutStartPopup from "./CheckoutStartPopup";
import PurchaseLeftPanel from "./PurchaseLeftPanel";
import PurchaseHeader from "./PurchaseHeader";
import PurchasePricePointRow from "./PurchasePricePointRow";
import PurchasePaymentMethodRow from "./PurchasePaymentMethodRow";
import PurchaseLegalRow from "./PurchaseLegalRow";
import PurchasePayButton from "./PurchasePayButton";

import {
  confirmPricePointPrepaidPurchase,
  confirmPricePointPrepaidPurchaseV2,
  confirmPricePointPurchase,
  confirmPricePointPurchaseV2,
  clearCheckoutUrl,
  getPrepaidSuccess,
  getPrepaidFailureCode,
  getPrepaidInvalidReasonCode,
  getPurchasedPricePoint,
  getPurchasedPlayerFacingId,
  getOptionsLoadingState,
  selectPaymentMethod,
  getPurchaseCurrency,
  getPurchaseCurrencyName
} from "../../redux/modules/paymentOptions";

import { getSessionStoreCode, getSessionUseApiV2 } from "../../redux/modules/session";

import {
  getErrorReason,
  resetErrorMessage,
  errorReasonEnum
} from "../../redux/modules/error";

const paymentMethodValidAmounts = { "pay1q-cyberposa": [990000], cybercvs: [] };

let purchaseDetails = {};

class KrPaymentOptions extends React.Component {
  state = {
    pricePoints: [],
    selectedPricePoint: {},
    selectedPaymentMethod: { name: "", title: "", bank: "", isPrepaid: false },
    checkoutPopupOpen: false,
    purchaseConfirmed: false,
    isWindows: navigator.appVersion.indexOf("Win") > -1,
    redirectTo: undefined,
    emailOverride: null,
    validCurrencyAmounts: null,
    allowPayButtonClick: true,
    isPreference: false
  };

  componentWillReceiveProps(nextProps) {
    if (
      this.state.pricePoints.length === 0 &&
      nextProps.paymentOptions.length > 0
    ) {
      this.setState({
        paymentOptions: nextProps.paymentOptions,
        pricePoints: nextProps.paymentOptions[0].pricePoints,
        selectedPricePoint: nextProps.paymentOptions[0].pricePoints[0]
      });
    }

    if (nextProps && nextProps.prepaidFailureCode) {
      this.setState({
        redirectTo: "checkout-failed",
        details: {
          success: false,
          prepaidFailureCode: nextProps.prepaidFailureCode,
          invalidReasonCode: nextProps.invalidReasonCode
        }
      });
    } else if (nextProps && nextProps.prepaidSuccess) {
      this.setState({
        redirectTo: "checkout-success",
        details: {
          success: true,
          playerFacingId: nextProps.purchasedPlayerFacingId,
          purchase: nextProps.purchasedPricePoint
        }
      });
    }
  }

  componentDidMount() {
    window.addEventListener("message", this.messageEventHandler, false);
    window.addEventListener("keydown", this.escapeKeyHandler);
    this.clearSavedStateDetails();
  }

  componentDidUpdate() {
    if (this.props.errorReason === errorReasonEnum.VELOCITY_ERROR) {
      alert(
        this.context.intl.formatMessage({ id: "kr_spending_cap_exceeded" })
      );
      this.props.resetErrorMessage();
      this.setState({
        checkoutPopupOpen: false,
        prepaidPopupOpen: false,
        allowPayButtonClick: true
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.messageEventHandler);
  }

  isGift() {
    return (
      this.props.purchaseContext !== null &&
      "gifteeSummonerName" in this.props.purchaseContext
    );
  }

  onPricePointSelect = pricePoint => {
    this.setState({ selectedPricePoint: pricePoint });
  };

  getSelectedOrFirstPricePoint(validCurrencyAmounts) {
    let validPricePoints = this.state.pricePoints;

    if (validCurrencyAmounts !== undefined) {
      validPricePoints = this.state.pricePoints.filter(
        pricePoint =>
          validCurrencyAmounts.indexOf(pricePoint.realAmountCents) >= 0
      );
    }

    return validPricePoints.indexOf(this.state.selectedPricePoint) >= 0
      ? this.state.selectedPricePoint
      : validPricePoints[0] || {};
  }

  getEffectiveEmail = () => {
    return this.state.emailOverride
      ? this.state.emailOverride
      : this.props.purchaseContext.userEmail;
  };

  getDate = () => {
    const now = new Date();
    now.setMonth(now.getMonth() + Number(this.state.monthLength || 0));

    return `${now.getFullYear()}년 ${now.getMonth() + 1}월 ${now.getDate()}일`;
  };

  getTotalVirtualAmount = () => {
    if (
      this.state.selectedPricePoint.virtualAmount !== null &&
      this.state.selectedPricePoint.virtualBonusAmount !== null
    ) {
      return (
        this.state.selectedPricePoint.virtualAmount +
        this.state.selectedPricePoint.virtualBonusAmount
      );
    } else {
      // no selected price point return null instad of NaN
      return null;
    }
  };

  setValidPricePoints = paymentMethod => {
    if (paymentMethod in paymentMethodValidAmounts) {
      const validCurrencyAmounts = paymentMethodValidAmounts[paymentMethod];

      const newState = { validCurrencyAmounts };

      const pricePoint = this.getSelectedOrFirstPricePoint(
        validCurrencyAmounts
      );

      if (pricePoint.id !== this.state.selectedPricePoint.id) {
        newState.selectedPricePoint = pricePoint;
      }

      this.setState(newState);
      return;
    }

    this.setState({
      validCurrencyAmounts: null,
      selectedPricePoint: this.getSelectedOrFirstPricePoint()
    });
  };

  onPaymentMethodSelect = (paymentMethod, paymentMethodTitle) => {
    const isPrepaid = paymentMethod === "cybercvs";
    this.setState({
      selectedPaymentMethod: {
        name: paymentMethod,
        title: paymentMethodTitle,
        bank: "",
        isPrepaid: isPrepaid
      }
    });
    this.props.selectPaymentMethod(paymentMethod);
    this.setValidPricePoints(paymentMethod);
  };

  onSelectCardBank = (paymentMethodBank, paymentMethodTitle) => {
    this.setState({
      selectedPaymentMethod: {
        ...this.state.selectedPaymentMethod,
        title: paymentMethodTitle,
        bank: paymentMethodBank
      }
    });
  };

  onCheckoutPopupClose = () => {
    this.setState({ allowPayButtonClick: true });
    this.setState({ checkoutPopupOpen: false, prepaidPopupOpen: false });
    this.props.clearCheckoutUrl();
    this.clearSavedStateDetails();
  };

  clearSavedStateDetails = () => {
    sessionStorage.removeItem("isGift");
    sessionStorage.removeItem("successPurchaseDetails");
    sessionStorage.removeItem("purchaseErrorOrPendingDetails");
  };

  escapeKeyHandler = ({ code }) => {
    if (code === "Escape") {
      this.onCheckoutPopupClose();
    }
  };

  messageEventHandler = message => {
    const resultData = message.data;
    if (resultData == null) {
      return;
    }
    const resultType = resultData.type;
    // See KrPaymentCancelled.js
    if (resultType === "checkout-cancelled") {
      this.onCheckoutPopupClose();
    } else if (resultType === "checkout-success"
    ) {
      this.setState({
        redirectTo: resultType,
        details: sessionStorage.successPurchaseDetails
      });
    } else if (
      resultType === "checkout-pending"
      || resultType === "checkout-failed") {
      this.setState({
        redirectTo: resultType,
        pendingDetails: sessionStorage.purchaseErrorOrPendingDetails
      });
    }
  };

  initBrandPayCardPref = () => {
    this.setState({
      selectedPaymentMethod: {
        name: "toss-prefbrandpaycard",
        title: "toss-prefcard",
        bank: "",
        isPrepaid: false
      }
    },
      () => { this.startCheckout() }
    );
  }

  initBrandPayBankPref = () => {
    this.setState({
      selectedPaymentMethod: {
        name: "toss-prefbrandpaybank",
        title: "toss-prefbank",
        bank: "",
        isPrepaid: false
      }
    },
      () => { this.startCheckout() }
    );
  }

  onPreferenceOpen = () => {
    this.setState({ purchaseConfirmed: true, isPreference: true },
      this.onPurchaseClick);
  }

  renderPurchaseResultRedirect(resultType) {
    if (!resultType) {
      return;
    }

    const { intl } = this.context;

    if (resultType === "checkout-failed") {
      if (this.state.details && this.state.details.prepaidFailureCode) {
        this.setState({ redirectTo: undefined });
        alert(
          this.mapErrorMessageToFailureCode(
            this.state.details.prepaidFailureCode
          )
        );
        return;
      } else {
        purchaseDetails = {
          errorMessage: intl.formatMessage({ id: "payment_error_text" })
        };
      }
    } else if (resultType === "checkout-success") {
      this.setState({ redirectTo: resultType });
      purchaseDetails = {
        ...sessionStorage.successPurchaseDetails,
        paymentMethod: this.state.selectedPaymentMethod.title,
        currencyAmount: this.state.selectedPricePoint.realAmountCents
      };

      // if we have an amount (from a prepaid purchase) then overwrite those amounts
      if (this.state.details && this.state.details.purchase) {
        purchaseDetails.currencyAmount = this.state.details.purchase.realAmountCents;
        purchaseDetails.virtualAmount =
          this.state.details.purchase.virtualAmount +
          this.state.details.purchase.virtualBonusAmount;
      }
    } else if (resultType === "checkout-pending") {
      this.setState({ redirectTo: resultType });
      // Pay1q virtual account purchases require that we display information passed in from Gateway
      // This will ensure that information is loaded and displayed      
      purchaseDetails = {
        ...sessionStorage.purchaseErrorOrPendingDetails,
        amount: Number(sessionStorage.purchaseErrorOrPendingDetails.amount)
      };
    }

    return (
      <Redirect
        push
        to={{
          pathname: `/kr/${resultType}`,
          state: { isGift: this.isGift(), purchaseDetails }
        }}
      />
    );
  }

  mapErrorMessageToFailureCode = failureCode => {
    switch (failureCode) {
      case "REDEEMED":
      case "NOTFOUND":
        return this.context.intl.formatMessage({
          id: `kr_prepaid_failure_${failureCode}`
        });
      case "VELOCITY":
        return this.context.intl.formatMessage({
          id: "kr_spending_cap_exceeded"
        });
      default:
        return this.context.intl.formatMessage({ id: "kr_prepaid_failure" });
    }
  };

  onConfirmPurchaseChange = () => {
    this.setState({ purchaseConfirmed: !this.state.purchaseConfirmed });
  };

  onConfirmPurchase = () => {
    this.setState({ purchaseConfirmed: true });
  };

  onPurchaseClick = () => {
    if (!this.state.purchaseConfirmed) {
      alert(this.context.intl.formatMessage({ id: "kr_purchase_confirm_product_alarm" }));
    } else if (this.state.selectedPaymentMethod.name === "toss-brandpaycard" && this.state.isPreference) {
      this.initBrandPayCardPref();
    } else if (this.state.selectedPaymentMethod.name === "toss-brandpaybank" && this.state.isPreference) {
      this.initBrandPayBankPref();
    } else if (
      this.state.selectedPaymentMethod.name === "inicis-creditcard"
      && !this.state.selectedPaymentMethod.bank
    ) {
      alert(this.context.intl.formatMessage({ id: "kr_purchase_select_card_type_alarm" }));
    } else if (this.state.selectedPaymentMethod.isPrepaid) {
      // if we're using cyber_cvs, start a prepaid checkout
      this.startPrepaid();
    } else {
      this.startCheckout();
    }
  };

  startPrepaid = () => {
    this.setState({ allowPayButtonClick: false });
    this.setState({ prepaidPopupOpen: true });
  };

  startCheckout = () => {
    this.setState({ allowPayButtonClick: false });
    this.setState({ checkoutPopupOpen: true });

    if (this.props.useApiV2) {
      this.props.confirmPricePointPurchaseV2(
        this.state.selectedPaymentMethod.name,
        this.state.selectedPricePoint.id
      );
    } else {
      this.props.confirmPricePointPurchase(
        this.props.sessionToken,
        this.state.selectedPaymentMethod.name,
        this.state.selectedPricePoint.id
      );
    }
    this.saveStateDetails();
  };

  saveStateDetails = () => {
    sessionStorage.setItem("isGift", this.isGift());
    var successPurchaseDetails = {
      'currencyAmount': this.state.selectedPricePoint.realAmountCents,
      'paymentDate': this.getDate(),
      'paymentMethod': this.state.selectedPaymentMethod.title,
      'virtualAmount': this.getTotalVirtualAmount()
    };
    sessionStorage.setItem('successPurchaseDetails', JSON.stringify(successPurchaseDetails));
  };

  onEmailChange = newEmail => {
    this.setState({ emailOverride: newEmail });
  };

  render() {
    const {
      paymentOptions,
      purchaseContext,
      virtualCurrencyCode,
      virtualCurrencyName
    } = this.props;
    const { intl } = this.context;

    const confirmPricePointPrepaidPurchase = this.props.useApiV2
      ? this.props.confirmPricePointPrepaidPurchaseV2
      : this.props.confirmPricePointPrepaidPurchase;

    return (
      <div>
        <Helmet>
          <title>
            {intl.formatMessage({
              id: "game_name_" + this.props.storeCode,
              defaultMessage: "League of Legends"
            })}
          </title>
          <body className="kr" />
        </Helmet>
        {paymentOptions.length === 0 ? (
          <div className="container loading">
            <div className="loading-spinner" />
          </div>
        ) : (
          <div className="kr-payment-options">
            {this.renderPurchaseResultRedirect(this.state.redirectTo)}
            {this.props.checkoutUrl && this.state.checkoutPopupOpen && (
              <CheckoutStartPopup
                onFrameClose={this.onCheckoutPopupClose}
                checkoutUrl={this.props.checkoutUrl}
                cardCode={this.state.selectedPaymentMethod.bank}
                userEmail={this.getEffectiveEmail()}
                paymentMethod={this.state.selectedPaymentMethod.title}
                virtualCurrencyCode={virtualCurrencyCode}
                virtualAmount={this.getTotalVirtualAmount()}
                isPrepaid={this.state.selectedPaymentMethod.isPrepaid}
                paymentMethodId={this.state.selectedPaymentMethod.name}
              />
            )}

            {this.state.prepaidPopupOpen && (
              <CheckoutStartPopup
                onFrameClose={this.onCheckoutPopupClose}
                confirmPricePointPrepaidPurchase={
                  confirmPricePointPrepaidPurchase
                }
                virtualCurrencyCode={virtualCurrencyCode}
                isPrepaid={this.state.selectedPaymentMethod.isPrepaid}
                sessionToken={this.props.sessionToken}
                loading={this.props.loading}
              />
            )}

            <PurchaseLeftPanel
              selectedVirtualCurrencyTotalAmount={this.getTotalVirtualAmount()}
              isGift={this.isGift()}
              purchaseContext={purchaseContext}
              onEmailChange={this.onEmailChange}
              emailOverride={this.state.emailOverride}
              virtualCurrencyCode={virtualCurrencyCode}
              virtualCurrencyName={virtualCurrencyName}
            />
            <div className="right-side">
              <PurchaseHeader
                virtualCurrencyCode={virtualCurrencyCode}
                storeCode={this.props.storeCode}
              />
              <PurchasePricePointRow
                activePricePointId={this.state.selectedPricePoint.id}
                onPricePointSelect={this.onPricePointSelect}
                pricePoints={this.state.pricePoints}
                validCurrencyAmounts={this.state.validCurrencyAmounts}
              />
              <PurchasePaymentMethodRow
                paymentOptions={paymentOptions}
                activePaymentMethod={this.state.selectedPaymentMethod}
                onPaymentMethodSelect={this.onPaymentMethodSelect}
                selectedPaymentMethod={this.state.selectedPaymentMethod}
                onSelectCardBank={this.onSelectCardBank}
                isWindows={this.state.isWindows}
                virtualCurrencyCode={virtualCurrencyCode}
              />
              <PurchaseLegalRow
                realAmountCents={this.state.selectedPricePoint.realAmountCents}
                onConfirmPurchaseChange={this.onConfirmPurchaseChange}
                onConfirmPurchase={this.onConfirmPurchase}
                purchaseConfirmed={this.state.purchaseConfirmed}
                selectedPaymentMethod={this.state.selectedPaymentMethod}
                isWindows={this.state.isWindows}
                virtualCurrencyCode={virtualCurrencyCode}
                gameCode={this.props.storeCode}
                onPurchaseClick={this.onPurchaseClick}
                onPreferenceOpen={this.onPreferenceOpen}
              />
              <div className="button-row">
                <PurchasePayButton onClick={this.onPurchaseClick} allowClick={this.state.allowPayButtonClick} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

KrPaymentOptions.contextTypes = {
  intl: PropTypes.object.isRequired
};

KrPaymentOptions.propTypes = {
  paymentOptions: PropTypes.array.isRequired,
  confirmPricePointPurchase: PropTypes.func.isRequired,
  confirmPricePointPurchaseV2: PropTypes.func.isRequired,
  confirmPricePointPrepaidPurchase: PropTypes.func.isRequired,
  confirmPricePointPrepaidPurchaseV2: PropTypes.func.isRequired,
  selectPaymentMethod: PropTypes.func.isRequired,
  checkoutUrl: PropTypes.string,
  sessionToken: PropTypes.string.isRequired,
  purchaseContext: PropTypes.object,
  errorReason: PropTypes.number,
  loading: PropTypes.bool,
  useApiV2: PropTypes.bool.isRequired,
  clearCheckoutUrl: PropTypes.func.isRequired,
  virtualCurrencyCode: PropTypes.string,
  virtualCurrencyName: PropTypes.string,
  storeCode: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  useApiV2: getSessionUseApiV2(state),
  errorReason: getErrorReason(state),
  prepaidSuccess: getPrepaidSuccess(state),
  prepaidFailureCode: getPrepaidFailureCode(state),
  prepaidInvalidReasonCode: getPrepaidInvalidReasonCode(state),
  purchasedPricePoint: getPurchasedPricePoint(state),
  purchasedPlayerFacingId: getPurchasedPlayerFacingId(state),
  loading: getOptionsLoadingState(state),
  virtualCurrencyCode: getPurchaseCurrency(state) === undefined ? undefined : getPurchaseCurrency(state).toLowerCase(),
  virtualCurrencyName: getPurchaseCurrencyName(state),
  storeCode: getSessionStoreCode(state)
});

export default connect(
  mapStateToProps,
  {
    confirmPricePointPurchase,
    confirmPricePointPurchaseV2,
    confirmPricePointPrepaidPurchase,
    confirmPricePointPrepaidPurchaseV2,
    clearCheckoutUrl,
    selectPaymentMethod,
    resetErrorMessage
  }
)(KrPaymentOptions);
