import iconMap from "./iconMap.json";

export default function(paymentMethod, platform, country, locale, currency) {
  const lowerPlatform = platform.toLowerCase();
  const countryCode = (country && country.code2) ? country.code2.toLowerCase() : "";
  const language = locale ? locale.substring(0, 2) : "";
  const priority0 = `${paymentMethod}.${lowerPlatform}.${countryCode}`;
  const priority1 = `${paymentMethod}.${countryCode}.${language}`;
  const priority2 = `${paymentMethod}.${lowerPlatform}.${language}`;
  const priority3 = `${paymentMethod}.${language}`;
  const priority4 = `${paymentMethod}.${countryCode}`;
  const priority5 = `${paymentMethod}.${currency}`;
  const priority6 = `${paymentMethod}.${lowerPlatform}`;
  const priority7 = `${paymentMethod}`;

  const priorities = [
    priority0,
    priority1,
    priority2,
    priority3,
    priority4,
    priority5,
    priority6,
    priority7
  ];

  for (const priority of priorities) {
    if (iconMap[priority]) {
      return `/images/pm/${iconMap[priority]}`;
    }
  }

  return undefined;
}
