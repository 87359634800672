import React from "react";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getSessionStoreCode } from "../../redux/modules/session";

import ConsentFormDescription from "./ConsentFormDescription";
import ConsentFormLengthPicker from "./ConsentFormLengthPicker";
import ConsentFormAgreement from "./ConsentFormAgreement";
import ConsentFormRegister from "./ConsentFormRegister";

import "./ConsentForm.css";

class ConsentForm extends React.Component {
  render() {
    return (
      <div className="kr-consent-container">
        <div className="kr-consent-disclaimer">
          <FormattedMessage id="kr_consent_disclaimer" />
        </div>

        <div className="kr-consent-contents">
          <dl className="kr-consent-section">
            <dt>
              <FormattedMessage id="kr_billing_agreement" />
            </dt>
            <dd>
              <div className="kr-consent-section-contents">
                <div className="kr-consent-rp-agree-inner">
                  {/*/!* Readonly component displays the scrollable div *!/*/}
                  <ConsentFormDescription storeCode={this.props.storeCode} />
                  {/* Registration length radio button list (raises event when radio clicked) */}
                  <ConsentFormLengthPicker
                    onMonthSelect={this.props.onMonthSelect}
                  />
                </div>
                {/* Agreement checkbox (raises event when checked) */}
                <ConsentFormAgreement
                  onAgreementCheck={this.props.onAgreementCheck}
                />
              </div>
            </dd>
          </dl>
        </div>

        <div className="kr-consent-contents new-section">
          <div className="kr-consent-section">
            <dt>
              <FormattedMessage id="kr_verify_agreement" />
            </dt>
            <dd>
              <div className="kr-consent-section-contents">
                <ul className="kr-consent-agreement-text">
                  <li>
                    <FormattedMessage id="kr_consent_fee" />
                  </li>
                  <li>
                    <FormattedMessage id="kr_applicant_identity_disclaimer" />
                  </li>
                </ul>
              </div>
            </dd>
          </div>
        </div>

        {/* Submit/Cancel buttons (raises event when submit button is clicked, cancel will just close window) */}
        <ConsentFormRegister onRegisterClick={this.props.onRegisterClick} />
      </div>
    );
  }
}

ConsentForm.propTypes = {
  intl: intlShape.isRequired,
  onAgreementCheck: PropTypes.func.isRequired,
  onMonthSelect: PropTypes.func.isRequired,
  onRegisterClick: PropTypes.func.isRequired,
  storeCode: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  storeCode: getSessionStoreCode(state)
});

export default connect(
  mapStateToProps,
  {}
)(injectIntl(ConsentForm));
