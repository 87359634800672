import PropTypes from "prop-types";
import React from "react";

class CheckoutStartPopupPricePoint extends React.Component {
  componentDidMount() {
    document.getElementById("kr-checkout-start-form").submit();
  }

  isOverlay() {
    return this.props.checkoutUrl.indexOf("pay1q") > -1 || this.props.checkoutUrl.indexOf("kakaopay") > -1;
  }

  render() {
    const {
      onFrameClose,
      checkoutUrl,
      cardCode,
      userEmail,
      paymentMethod,
      virtualAmount,
      paymentMethodId
    } = this.props;

    if (paymentMethodId === "toss-creditcard"
      || paymentMethodId === "toss-tosspay"
      || paymentMethodId === "toss-brandpaycard"
      || paymentMethodId === "toss-brandpaybank"
      || paymentMethodId === "pay1q-kftc"
      || paymentMethodId === "pay1q-payco"
      || paymentMethodId === "pay1q-book"
      || paymentMethodId === "pay1q-smartculture"
      || paymentMethodId === "pay1q-vacct"
      || paymentMethodId === "pay1q-teencash"
      || paymentMethodId === "pay1q-happymoney"
      || paymentMethodId === "pay1q-culture"
      || paymentMethodId === "pay1q-impaymobile"
      || paymentMethodId === "kakaopay") {
      return (
        <div>
          {this.isOverlay() && <div className="kr-checkout-start-background" />}
          <div
            className={`kr-checkout-start-modal${this.isOverlay() ? "-overlay" : ""
              }`}
          >
            {this.isOverlay() && (
              <div className="kr-checkout-start-modal-header">
                <label onClick={onFrameClose}>&times;</label>
              </div>
            )}
            <form
              id="kr-checkout-start-form"
              target="_self"
              action={checkoutUrl}
              method="POST"
            >
              {cardCode && (
                <input type="text" name="cardCode" defaultValue={cardCode} />
              )}
              {userEmail && (
                <input type="text" name="userEmail" defaultValue={userEmail} />
              )}
              {paymentMethod && (
                <input
                  type="text"
                  name="paymentMethod"
                  defaultValue={paymentMethod}
                />
              )}
              {virtualAmount && (
                <input
                  type="text"
                  name="virtualAmount"
                  defaultValue={virtualAmount}
                />
              )}
            </form>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {this.isOverlay() && <div className="kr-checkout-start-background" />}
          <div
            className={`kr-checkout-start-modal${this.isOverlay() ? "-overlay" : ""
              }`}
          >
            {this.isOverlay() && (
              <div className="kr-checkout-start-modal-header">
                <label onClick={onFrameClose}>&times;</label>
              </div>
            )}
            <iframe
              name="kr-checkout-start-iframe"
              className={`kr-checkout-start-iframe${this.isOverlay() ? "-overlay" : ""
                }`}
              title="Checkout Start"
              src=""
            />
            <form
              id="kr-checkout-start-form"
              target="kr-checkout-start-iframe"
              action={checkoutUrl}
              method="POST"
            >
              {cardCode && (
                <input type="text" name="cardCode" defaultValue={cardCode} />
              )}
              {userEmail && (
                <input type="text" name="userEmail" defaultValue={userEmail} />
              )}
              {paymentMethod && (
                <input
                  type="text"
                  name="paymentMethod"
                  defaultValue={paymentMethod}
                />
              )}
              {virtualAmount && (
                <input
                  type="text"
                  name="virtualAmount"
                  defaultValue={virtualAmount}
                />
              )}
            </form>
          </div>
        </div>
      );
    }
  }
}

CheckoutStartPopupPricePoint.propTypes = {
  checkoutUrl: PropTypes.string.isRequired,
  onFrameClose: PropTypes.func.isRequired,
  cardCode: PropTypes.string,
  userEmail: PropTypes.string,
  paymentMethod: PropTypes.string,
  virtualAmount: PropTypes.number,
  paymentMethodId: PropTypes.string
};

export default CheckoutStartPopupPricePoint;
