import React from "react";
import PropTypes from "prop-types";
import { errorReasonEnum } from "../redux/modules/error";
import { connect } from "react-redux";
import { resetErrorMessage } from "../redux/modules/error";
import PaymentExpired from "../components/PaymentExpired";
import PaymentError from "../components/PaymentError";
import PurchaseConsent from "./PurchaseConsent";

// This is for handling payment errors - for client errors, see ErrorBoundary
class ErrorHandler extends React.Component {
  onAckError = () => {
    this.props.resetErrorMessage();
    this.context.router.history.push("/");
  };

  render() {
    let { errorReason } = this.props;

    if (errorReason === errorReasonEnum.AUTH_ERROR) {
      return <PaymentExpired />;
    }

    if (errorReason === errorReasonEnum.VELOCITY_ERROR) {
      return (
        <PaymentError errorCode="velocity-error" onAckError={this.onAckError} />
      );
    }

    if (errorReason === errorReasonEnum.PAYMENT_METHOD_NOT_ALLOWED) {
      return (
        <PaymentError
          errorCode="payment-method-not-allowed-error"
          onAckError={this.onAckError}
        />
      );
    }

    if (errorReason === errorReasonEnum.CONSENT_REQUIRED) {
      return <PurchaseConsent />;
    }

    if (!errorReasonEnum.isDefined(errorReason)) {
      return <PaymentError onAckError={this.onAckError} />;
    }

    return <div />;
  }
}

ErrorHandler.contextTypes = {
  router: PropTypes.object
};

ErrorHandler.propTypes = {
  resetErrorMessage: PropTypes.func.isRequired,
  errorReason: PropTypes.number
};

export default connect(null, { resetErrorMessage })(ErrorHandler);
