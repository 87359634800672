// You may add additional domains to this list if you so choose
const ALLOWED_DOMAINS = [
  "sandbox-secure.xsolla.com",
  "secure.xsolla.com",
  "www.sandbox.paypal.com",
  "www.paypal.com",
  "apitest.smart2pay.com",
  "globalapi.smart2pay.com",
  "gw-apm-globalpayapi.nuvei.com", // for testing smart2pay
  "r2.girogate.de", // backup provider (called PPRO) for smart2pay
  "r3.girogate.de" // backup provider (called PPRO) for smart2pay
];

/**
 * Determines if the current URL is actually from one of the items in array.
 * @param {String} url
 */
export default function isExternalBrowserURL(url) {
  try {
    const uri = new URL(url);
    // If the uri protocol isn't https or the url is not allowed, throw an error
    if (!(uri.protocol === "https:" && ALLOWED_DOMAINS.includes(uri.hostname))) {
      throw new Error("This URL redirect is not allowed.");
    }
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}
