import superagent from "superagent";
import throttle from "lodash/throttle";

const methods = ["get", "post", "put", "patch", "del"];

const errorPath = "riotpay/pmc/v1/client-logs/error";

function formatUrl(pmcCoreHost, instance, path) {
  // production pmc edge has multiple instances (config url will contain '{}' token)
  // but local and stage pmc edge have but one instance (so no token would be in config url)
  const pmcCoreHostInstance = pmcCoreHost.replace("{}", instance);
  const adjustedPath = path[0] !== "/" ? `/${path}` : path;
  return `${pmcCoreHostInstance}${adjustedPath}`;
}

/* Formats http requests and converts it to Promises */
export default class ApiClient {
  constructor(pmcCoreHost) {
    methods.forEach(
      method =>
        (this[method] = (
          path,
          { params, data, token, instance, useApiV2 } = {}
        ) =>
          new Promise((resolve, reject) => {
            const request = superagent[method](
              formatUrl(pmcCoreHost, instance, path)
            );

            if (useApiV2) {
              if (token) {
                request.set("Authorization", "Session " + token);
              }

              if (params) {
                request.query(params);
              }

              if (data) {
                request.set("Content-Type", "application/json");
                request.send(data);
              }
            } else {
              if (!params && !data) {
                request.query({
                  pid: token
                });
              }

              if (params) {
                request.query({
                  ...params,
                  pid: token
                });
              }

              if (data) {
                request.set("Content-Type", "application/json");
                request.send({
                  ...data,
                  pid: token
                });
              }
            }

            request.end((err, res) => {
              if (err) {
                const error = {};
                error.data = res && res.text ? res.text : err;
                error.status = err.status || err.response;
                reject(error);
              } else {
                resolve(res.body);
              }
            });
          }))
    );

    this.throttleLogError = throttle(
      (m, a, t) =>
        this.post(errorPath, {
          data: {
            token: t,
            message: m,
            action: a
          }
        }).catch(err => {
          // Do not treat session expiration (HTTP 401) as an error
          if (err.status !== 401) {
            console.error("Unable to report error:", err);
          }
        }),
      10000,
      { leading: true }
    );
  }

  // Sends first error request right away and then make sure we don't send the same
  // error less than 10 seconds again.
  logError = token => (message, action) => {
    this.throttleLogError(message, action, token);
  };
  /*
   * There's a V8 bug where, when using Babel, exporting classes with only
   * constructors sometimes fails. Until it's patched, this is a solution to
   * "ApiClient is not defined" from issue #14.
   * https://github.com/erikras/react-redux-universal-hot-example/issues/14
   *
   * Relevant Babel bug (but they claim it's V8): https://phabricator.babeljs.io/T2455
   *
   * Remove it at your own risk.
   */
  empty() {}
}
