import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import "./ConsentFormLengthPicker.css";

class ConsentFormLengthPicker extends React.Component {
  onCheckChanged = e => {
    this.props.onMonthSelect(e.target.value);
  };

  render() {
    return (
      <div className="kr-consent-month-picker" style={{ color: "#787878" }}>
        <div className="kr-consent-month-picker-title">
          <FormattedMessage id="kr_consent_agreement_period" />
        </div>

        <div className="kr-consent-month-picker-content">
          <div className="kr-consent-radio">
            <input
              type="radio"
              name="radioMonth"
              id="radioMonth1"
              value="1"
              className="kr-consent-radio"
              onChange={this.onCheckChanged}
            />
            <label htmlFor="radioMonth1">
              <FormattedMessage id="kr_one_month" defaultMessage="1 month" />
            </label>
          </div>
          <div className="kr-consent-radio">
            <input
              type="radio"
              name="radioMonth"
              id="radioMonth3"
              value="3"
              className="kr-consent-radio"
              onChange={this.onCheckChanged}
            />
            <label htmlFor="radioMonth3">
              <FormattedMessage id="kr_three_month" defaultMessage="3 months" />
            </label>
          </div>
          <div className="kr-consent-radio">
            <input
              type="radio"
              name="radioMonth"
              id="radioMonth6"
              value="6"
              className="kr-consent-radio"
              onChange={this.onCheckChanged}
            />
            <label htmlFor="radioMonth6">
              <FormattedMessage id="kr_six_month" defaultMessage="6 months" />
            </label>
          </div>
          <div className="kr-consent-radio">
            <input
              type="radio"
              name="radioMonth"
              id="radioMonth9"
              value="9"
              className="kr-consent-radio"
              onChange={this.onCheckChanged}
            />
            <label htmlFor="radioMonth9">
              <FormattedMessage id="kr_nine_month" defaultMessage="9 months" />
            </label>
          </div>
          <div className="kr-consent-radio">
            <input
              type="radio"
              name="radioMonth"
              id="radioMonth12"
              value="12"
              className="kr-consent-radio"
              onChange={this.onCheckChanged}
            />
            <label htmlFor="radioMonth12">
              <FormattedMessage
                id="kr_twelve_month"
                defaultMessage="12 month"
              />
            </label>
          </div>
        </div>
        <p>
          <FormattedMessage id="kr_after_consent_expired" />
        </p>
      </div>
    );
  }
}

ConsentFormLengthPicker.propTypes = {
  onMonthSelect: PropTypes.func.isRequired
};

export default ConsentFormLengthPicker;
