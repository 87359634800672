import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

import reducers from "./modules/reducers";
import apiMiddleware from "./middleware/api";
import thunkMiddleware from "redux-thunk";

export default (initialState, client) => {
  let enhancer = composeWithDevTools(
    applyMiddleware(thunkMiddleware, apiMiddleware(client))
  );

  return createStore(reducers, initialState, enhancer);
};
