import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { FormattedMessage } from "react-intl";

import PurchaseHeader from "./PurchaseHeader";
import { formatNumberWithCommas } from "../../utils/formatters";

import "./ResultPage.css";

const PurchaseResultPending = ({
  bankName,
  accountNumber,
  depositorName,
  expirationDate,
  amount,
  virtualCurrencyCode,
  storeCode
},
  { intl }
) => (
  <div>
    <Helmet>
      <body className="kr" />
      <title>정상적으로 완료되었습니다.</title>
    </Helmet>
    <div className="right-side">
      <div className="content">
        <PurchaseHeader
          virtualCurrencyCode={virtualCurrencyCode}
          storeCode={storeCode}
        />

        <div className="kr-results">
          <div className="kr-result-header">
            <p>
              <span className="red">가상 계좌 신청</span>이
              <br />
              정상적으로 완료되었습니다.
            </p>
            <p className="more-info">
              결제 정보를 확인하시고 유효일 내로 입금해 주세요.
            </p>
          </div>
          <div className="kr-result-info">
            <h4>
              <FormattedMessage
                id="kr_payment_details"
                defaultMessage="결제 내역"
              />
            </h4>
            <table
              cellPadding="0"
              cellSpacing="0"
              summary="결제일 결제수단 결제금액"
            >
              <tbody>
                <tr>
                  <th className="pending" scope="row">가상계좌 번호</th>
                  <td>
                    {bankName}: {accountNumber}
                  </td>
                </tr>
                <tr>
                  <th className="pending" scope="row">가상계좌 유효일자</th>
                  <td>{expirationDate}</td>
                </tr>
                <tr>
                  <th className="pending" scope="row">가상계좌 예금주 명</th>
                  <td>{depositorName}</td>
                </tr>
                <tr className="red">
                  <th className="pending" scope="row">가상계좌 입금액</th>
                  <td>{formatNumberWithCommas(amount)}원</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="bottom-line" />
      </div>
    </div>
  </div>
);

PurchaseResultPending.propTypes = {
  bankName: PropTypes.string.isRequired,
  accountNumber: PropTypes.string.isRequired,
  depositorName: PropTypes.string.isRequired,
  expirationDate: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  virtualCurrencyCode: PropTypes.string.isRequired,
  storeCode: PropTypes.string.isRequired
};

export default PurchaseResultPending;
