/**
 * Determines if the current URL is actually from one of the items in array.
 * @param {Object} constPaymentMethods - map of payment method ids to (maps of) titles - { pay1q-toss: { title: 토스 } }
 * @param {List} enabledPaymentMethods - list of enabled payment method ids - [pay1q-toss, pay1q-payco,...]
 */
export default function selectFirstAvailablePaymentMethod(constPaymentMethods, enabledPaymentMethods) {
  const keys = Object.keys(constPaymentMethods);

  for (let i = 0; i < keys.length; i++) {
    if (enabledPaymentMethods.indexOf(keys[i]) > -1) {
      return {
        name: keys[i],
        title: constPaymentMethods[keys[i]].title
      };
    }
  }

  return undefined;
}
