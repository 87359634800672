const queryString = require("query-string");

export const formatNumberWithCommas = value =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const addOrIncrementRetryCountInUrl = () => {
  var parsed = queryString.parseUrl(window.location.href);

  if (Object.keys(parsed.query).length === 0) {
    return { url: parsed.url + "?retry=0", retry: 0 };
  }

  if (parsed.query.retry) {
    parsed.query.retry = parseInt(parsed.query.retry, 10) + 1;
  } else {
    parsed.query.retry = 0;
  }

  return {
    url: parsed.url + "?" + queryString.stringify(parsed.query),
    retry: parsed.query.retry
  };
};
