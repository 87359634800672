import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import ModalDialogLink from "../components/ModalDialogLink";

class EuRightToCancel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agreementCheckboxChecked: false
    };

    props.setCanStartPurchase(false);

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  onClickHandler() {
    const agreementCheckboxChecked = !this.state.agreementCheckboxChecked;
    this.setState({ agreementCheckboxChecked });
    this.props.setCanStartPurchase(agreementCheckboxChecked);
  }

  render() {
    const { attemptedPurchase } = this.props;
    const { agreementCheckboxChecked } = this.state;

    return (
      <div
        className={classNames("terms eu", {
          "text-warn": attemptedPurchase && !agreementCheckboxChecked,
          "eu-checked": agreementCheckboxChecked
        })}
      >
        <input type="checkbox" id="euCheckbox" checked={agreementCheckboxChecked} onClick={this.onClickHandler} />
        <FormattedMessage
          id="right_to_cancel_text"
          defaultMessage="I consent to Riot Games immediately beginning to perform the contract by activating digital content (including digital currency and any content activated using digital currency) in my account. I accept that I lose the {rightToCancelLink} and get a refund once the digital content has been activated in my account."
          values={{
            rightToCancelLink: (
              <ModalDialogLink
                linkTextLocId="right_to_cancel_contract_link"
                linkTextDefaultMessage="right to cancel this contract"
                modalTitleLocId="right_to_cancel_title"
                modalTitleDefaultMessage="Right to Cancel"
                modalContentsFileName="right_to_cancel_eu"
              />
            )
          }}
        />
      </div>
    );
  }
}

EuRightToCancel.propTypes = {
  attemptedPurchase: PropTypes.bool.isRequired,
  setCanStartPurchase: PropTypes.func.isRequired
};

export default EuRightToCancel;
