import PropTypes from "prop-types";
import React from "react";
import TooltipTrigger from "react-popper-tooltip";

const Tooltip = ({ children, tooltip, ...props }) => (
  <TooltipTrigger
    {...props}
    tooltip={({ tooltipRef, getTooltipProps }) => (
      <div
        {...getTooltipProps({
          ref: tooltipRef
        })}
      >
        {tooltip}
      </div>
    )}
  >
    {({ getTriggerProps, triggerRef }) => (
      <span
        {...getTriggerProps({
          ref: triggerRef
        })}
      >
        {children}
      </span>
    )}
  </TooltipTrigger>
);

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.node.isRequired
};

export default Tooltip;
