import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const VirtualContent = ({ content }) => {
  const contentRows = content.map((item, index) => (
    <tr key={index}>
      <th style={{wordBreak:'keep-all'}}>{item.name}</th>
      <td>{item.description}</td>
    </tr>
  ));

  return (
    <div className="sidebar-content">
      <h1>
        <FormattedMessage
          id="virtual-content"
          defaultMessage="Unlocked Item(s)"
        />
      </h1>
      <table>
        <tbody>{contentRows}</tbody>
      </table>
    </div>
  );
};

VirtualContent.propTypes = {
  content: PropTypes.array.isRequired
};

export default VirtualContent;