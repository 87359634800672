import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import "./ConsentFormAgreement.css";

class ConsentFormAgreement extends React.Component {
  state = {
    checkboxBgUrl: `url(${"../images/kr/Agree_off.png"})`
  };

  onCheckChanged = e => {
    let newBgUrl = e.target.checked
      ? `url(${"../images/kr/Agree_on.png"})`
      : `url(${"../images/kr/Agree_off.png"})`;
    this.setState({ checkboxBgUrl: newBgUrl });

    this.props.onAgreementCheck(e.target.checked);
  };

  render() {
    // In IE, checkbox will still be displayed over the background image if the image is assigned to the checkbox.
    // So we need to hide the checkbox completely and set the background image to the label.
    let checkboxImageStyle = {
      cursor: "pointer",
      width: "119px",
      height: "40px",
      backgroundImage: this.state.checkboxBgUrl,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      margin: "0"
    };

    return (
      <div className="kr-consent-agreement-content">
        <div className="kr-consent-agreement-content-label">
          <label htmlFor="checkAgree">
            <FormattedMessage id="kr_consent_confirm" />
          </label>
        </div>
        <div className="kr-consent-agreement-button-section">
          <input
            id="checkAgree"
            type="checkbox"
            style={{ display: "none" }}
            onChange={this.onCheckChanged}
          />
          <label htmlFor="checkAgree" style={checkboxImageStyle} />
        </div>
      </div>
    );
  }
}

ConsentFormAgreement.propTypes = {
  onAgreementCheck: PropTypes.func.isRequired
};

export default ConsentFormAgreement;
