import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import Currency from "./Currency";

const DirectPurchaseCheckoutDetail = ({ amount, amountInCents, currency, description }) => {
  // Direct purchases API v2 uses 'amountInCents' instead of 'amount'
  const amountToFormat = (amount !== undefined) ? amount : amountInCents;
  const formattedAmount = <Currency value={amountToFormat / 100} currency={currency} />;

  return (
    <div className="order-detail">
      <h2>
        <FormattedMessage id="order_details" defaultMessage="Order Details" />
      </h2>
      <div className="rpCard">
        <div className="price">
          {formattedAmount}
        </div>
        <div className="vcTotal">
          {description}
        </div>
      </div>

      <div className="table">
        <div className="rpRow">
          <div className="label">
            {description}
          </div>
        </div>
        <div className="totalRow">
          <div className="label">
            <FormattedMessage id="total" defaultMessage="Total" />:
          </div>
          <div className="value">
            {formattedAmount}
          </div>
        </div>
      </div>
    </div>
  );
};

DirectPurchaseCheckoutDetail.propTypes = {
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  description: PropTypes.string
};

export default DirectPurchaseCheckoutDetail;
