import { CALL_API } from "../middleware/api";

export const CONFIRM_CONSENT_REGISTER =
  "pmc-client/purchase-consent/CONFIRM_CONSENT_REGISTER";

export const CONFIRM_CONSENT_REGISTER_SUCCESS =
  "pmc-client/purchase-consent/CONFIRM_CONSENT_REGISTER_SUCCESS";

export const CONFIRM_CONSENT_REGISTER_FAIL =
  "pmc-client/purchase-consent/CONFIRM_CONSENT_REGISTER_FAIL";

export const confirmConsentRegistration = (
  consentDuration,
  accessToken
) => ({
  [CALL_API]: {
    types: [
      CONFIRM_CONSENT_REGISTER,
      CONFIRM_CONSENT_REGISTER_SUCCESS,
      CONFIRM_CONSENT_REGISTER_FAIL
    ],
    endpoint: "/riotpay/pmc/v1/purchase-consents",
    data: {
      consentDuration,
      accessToken
    }
  }
});

export const confirmConsentRegistrationV2 = (
  consentDuration,
  accessToken
) => ({
  [CALL_API]: {
    types: [
      CONFIRM_CONSENT_REGISTER,
      CONFIRM_CONSENT_REGISTER_SUCCESS,
      CONFIRM_CONSENT_REGISTER_FAIL
    ],
    endpoint: "/riotpay/pmc/v2/purchase-consents",
    data: {
      consentDuration,
      accessToken
    }
  }
});

export const initialState = {
  confirmed: false,
  confirming: false,
  loaded: false,
  loading: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CONFIRM_CONSENT_REGISTER: {
      return {
        ...state,
        confirming: true
      };
    }
    case CONFIRM_CONSENT_REGISTER_SUCCESS: {
      return {
        ...state,
        confirmed: true,
        confirming: false
      };
    }
    default:
      return state;
  }
}

export const getIsConfirmed = state => state.purchaseConsent.confirmed;
export const getIsLoaded = state => state.purchaseConsent.loaded;
