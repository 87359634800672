import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import "./PurchaseLegalPricePopup.css";

import { formatNumberWithCommas } from "../../utils/formatters";

const PurchaseLegalPricePopup = ({ open, onClose, realAmountCents, virtualCurrencyCode }) => (
  <div>
    {open && (
      <div className="popup">
        <div className="popup-main">
          <ul>
            <li>- 상품명 :{" "}
              <FormattedMessage
                  id={`vc_code_${virtualCurrencyCode}`}
                  defaultMessage={virtualCurrencyCode}
              />
              </li>
            {realAmountCents &&
              (<li>
                - 상품가격 :{" "}
                <span id="amt_fillup">
                  {formatNumberWithCommas(realAmountCents / 100)}원
                </span>
              </li>)}
            <li>- 상품 유효기간 : 마지막 결제일로부터 5년</li>
          </ul>
          <div className="close">
            <a href="#/" onClick={onClose}>&times;</a>
          </div>
        </div>
      </div>
    )}
  </div>
);

PurchaseLegalPricePopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  realAmountCents: PropTypes.number,
  virtualCurrencyCode: PropTypes.string.isRequired
};

export default PurchaseLegalPricePopup;
