import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import resolveIcon from "../utils/iconResolver";

class PaymentMethodItem extends React.PureComponent {
  onPaymentMethodSelect = () => {
    this.props.onPaymentMethodSelect(this.props.uniquePaymentMethodId);
  };
  render() {
    const { selected, paymentMethodId, platform, country, locale, currency } = this.props;
    return (
      <li
        onClick={this.onPaymentMethodSelect}
        className={classNames({ selected: selected })}
      >
        <img
          src={`${resolveIcon(paymentMethodId, platform, country, locale, currency)}`}
          alt={paymentMethodId}
        />
      </li>
    );
  }
}

PaymentMethodItem.contextTypes = {
  intl: PropTypes.object.isRequired
};

PaymentMethodItem.propTypes = {
  onPaymentMethodSelect: PropTypes.func.isRequired,
  country: PropTypes.object.isRequired,
  platform: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  paymentMethodId: PropTypes.string.isRequired,
  uniquePaymentMethodId: PropTypes.string.isRequired
};

export default PaymentMethodItem;
