import {
  getSessionToken,
  getSessionInstance,
  getSessionUseApiV2
} from "../modules/session";

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
function callApi(
  client,
  endpoint,
  data,
  method,
  params,
  token,
  instance,
  useApiV2
) {
  let promise;
  if (method) {
    promise = client[method](endpoint, {
      data,
      params,
      token,
      instance,
      useApiV2
    });
  } else if (data) {
    promise = client.post(endpoint, {
      data,
      params,
      token,
      instance,
      useApiV2
    });
  } else {
    promise = client.get(endpoint, { params, token, instance, useApiV2 });
  }
  return promise;
}

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol("Call API");

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default client => store => next => action => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === "undefined") {
    return next(action);
  }

  const { types, data, method, params, endpoint } = callAPI;
  const sessionToken = getSessionToken(store.getState());
  const instance = getSessionInstance(store.getState());
  const useApiV2 = getSessionUseApiV2(store.getState());

  const [requestType, successType, failureType] = types;

  const actionWith = resultData => {
    const finalData = resultData;
    const finalAction = Object.assign({}, action, finalData);
    delete finalAction[CALL_API];
    return finalAction;
  };

  if (sessionToken === "") {
    return next(
      actionWith({
        type: failureType,
        error: "Missing session token"
      })
    );
  }

  if (typeof endpoint !== "string") {
    return next(
      actionWith({
        type: failureType,
        error: "Specify a string endpoint URL."
      })
    );
  }
  if (!Array.isArray(types) || types.length !== 3) {
    const error = "Expected an array of three action types";
    client.logError(sessionToken)(error);
    return next(
      actionWith({
        type: failureType,
        error
      })
    );
  }
  if (!types.every(type => typeof type === "string")) {
    const error = "Expected action types to be strings";
    client.logError(sessionToken)(error);
    return next(
      actionWith({
        type: failureType,
        error
      })
    );
  }

  next(actionWith({ type: requestType }));

  return callApi(
    client,
    endpoint,
    data,
    method,
    params,
    sessionToken,
    instance,
    useApiV2
  ).then(
    response => next(actionWith({ response, type: successType })),
    error => {
      let message = "";
      let errorCode = "";
      let httpStatus = 0;
      try {
        const errorData = JSON.parse(error.data);
        if (errorData) {
          httpStatus = errorData.httpStatus;
          message = errorData.message || errorData.errorCode;
          errorCode = errorData.errorCode;
        }
      } catch (e) {
        if (error && error.message) {
          message = `Received error while calling pmc edge: ${error.message}`;
        } else if (typeof error === "string") {
          message = `Received error while calling pmc edge: ${error}`;
        } else {
          message = "Unknown error occured while calling pmc edge";
        }
      }

      // 429 is a velocity check and 404 is a code not found errors,
      // these don't need to be logged as errors.
      if (!error.status === 404 && !error.status === 429) {
        // Log error to the edge
        client.logError(sessionToken)(
          `HttpStatus: ${error.status} Error: ${message}`,
          failureType
        );
      }

      return next(
        actionWith({
          type: failureType,
          error: message || "Unexpected error happened",
          httpStatus,
          errorCode
        })
      );
    }
  );
};
